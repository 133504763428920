﻿(function () {
    'use strict';

    angular
        .module('app')
        .factory('FlashService', FlashService);

    FlashService.$inject = ['$rootScope'];
    function FlashService($rootScope) {
        var service = {};

        service.Success = Success;
        service.Error = Error;

        initService();

        return service;

        function initService() {
            $rootScope.$on('$locationChangeStart', function () {
				clearFlashMessage();
            });

            function clearFlashMessage() {
                var flash = $rootScope[$rootScope.flashName];
                if (flash) {
                    if (!flash.keepAfterLocationChange) {
                        delete $rootScope[$rootScope.flashName];
                    } else {
                        // only keep for a single location change
                        flash.keepAfterLocationChange = false;
                    }
                }
            }
        }

        function Success(message, keepAfterLocationChange, altObj) {
            $rootScope.flashName = 'flash'; //(altObj ? altObj : 'flash');
            $rootScope[$rootScope.flashName] = {
                message: message,
                type: 'success', 
				showClose: true,
                keepAfterLocationChange: keepAfterLocationChange
            };
			window.setTimeout(function() { delete $rootScope.flash; }, 500);
        }

        function Error(message, keepAfterLocationChange, altObj) {
			$rootScope.flashName = 'flash'; //(altObj ? altObj : 'flash');
            $rootScope[$rootScope.flashName] = {
                message: message,
                type: 'error',
				showClose: true,
                keepAfterLocationChange: keepAfterLocationChange
            };
			window.setTimeout(function() { delete $rootScope.flash; }, 500);
        }
		
		function CloseFlash() {
			delete $rootScope.flash;
		}
    }

})();