(function () {
    'use strict';

	angular.module('app').filter('diagnosticFilter', function() {
		return function(input, query) {
			var out = [];

			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.email) || re.test(obj.description)) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});

    angular.module('app').controller('DiagnosticController', DiagnosticController);
    DiagnosticController.$inject = ['LaredoService', '$scope', 'permissions'];
    function DiagnosticController(LaredoService, $scope, permissions) {
        var ctrl = this;
		$scope.maxPageSize = 5;
		$scope.currentPage = 0;
		$scope.diagnostics = [];
		$scope.gap = 5;
		$scope.maxPageItems = 20;
		$scope.locationId = $scope.globals.currentLocation;

		$scope.$on('currentLocationChange' , function (event, obj){
			$scope.locationId = obj.locationId;
			$scope.diagnostics = [];
			ctrl.getAllDiagnostic();
		});

		ctrl.getAllDiagnostic = function() {
			if (permissions.hasPermission('is_admin')){

				LaredoService.GetAllDiagnostic($scope.query || '', $scope.currentPage, 0).then(function(response) {
					if (response.success === 200) {
						$scope.diagnostics = response.diagnostics;
					}
				});
			}else{
				LaredoService.GetAllDiagnostic($scope.query || '', $scope.currentPage, $scope.locationId).then(function(response) {
					if (response.success === 200) {
						$scope.diagnostics = response.diagnostics;
					}
				});

			}

		};

		$scope.prevPage = function() {
			console.log("QUERY" , $scope.filter);
			if ($scope.currentPage > 0) {
				$scope.currentPage--;
				ctrl.getAllDiagnostic($scope.locationId);
			}
		};

		$scope.nextPage = function() {
			if ($scope.currentPage < $scope.diagnostics.length - 1) {
				$scope.currentPage++;
				ctrl.getAllDiagnostic();
			}
		};

		$scope.setPage = function() {
			console.log("PAGE" , $scope.currentPage, this.n);
			$scope.currentPage = this.n;
			ctrl.getAllDiagnostic();
		};

		ctrl.getAllDiagnostic();

    }
	angular.module('app').controller('DiagnosticDetailController', DiagnosticDetailController);
    DiagnosticDetailController.$inject = ['LaredoService', '$scope', '$routeParams' ];
    function DiagnosticDetailController(LaredoService, $scope, $routeParams) {
        var ctrl = this;

		$scope.diagnostic = {};
		$scope.beacons = "";
		$scope.beaconsDetected = "No";
		$scope.isCheckedIn = ""
		$scope.reason = ""



		ctrl.getDiagnosticById = function() {
			const diagnosticId = $routeParams.diagnosticId;
			console.log("calling getDiagnosticById", diagnosticId)
            LaredoService.GetDiagnosticById(diagnosticId).then(function(response) {
				console.log("getDiagnosticById response", response)
					if (response.success == 200) {
						$scope.diagnostic = response.diagnostics[0];
						const Flag_Eligible = 'Eligible';
						const Flag_Ineligible = 'Ineligible';
						const Flag_Eligibility_Verified = 'Eligibility Verified';
						const Remarks_EverthingIsFine = 'Everything is fine';
						const Remarks_NoBeaconDetected = 'No beacon detected';
						const Remarks_CheckOut = 'You have been check-out';
						const Remarks_PendingCheckIn = 'Pending check-in';
						let eligibility = Flag_Eligible;

						if ($scope.diagnostic.flags === 1 ) {
							eligibility = Flag_Ineligible;
						} else if ($scope.diagnostic.flags === 2) {
							eligibility = Flag_Eligibility_Verified;
						}

						$scope.diagnostic.flags = eligibility
						$scope.diagnostic.verified = $scope.diagnostic.verified === 1 ? "Yes" : "No",
						$scope.diagnostic.locationActive = $scope.diagnostic.locationActive === "1" ? "Yes" : "No";
						$scope.diagnostic.locationIstest = $scope.diagnostic.locationIstest === "1" ? "Yes" : "No";
						$scope.diagnostic.location_permission = $scope.diagnostic.location_permission === 1 ? "Granted" : "Denied";
						$scope.diagnostic.bluetooth_permission = $scope.diagnostic.bluetooth_permission === 1 ? "Granted" : "Denied";
						$scope.diagnostic.data_internet_permission = $scope.diagnostic.data_internet_permission === 1 ? "Granted" : "Denied";
						$scope.diagnostic.notification_permission = $scope.diagnostic.notification_permission === 1 ? "Granted" : "Denied";

						if($scope.diagnostic.beaconList) {
							if($scope.diagnostic.beaconList.beacons && $scope.diagnostic.beaconList.beacons.length > 0)
							{
								if ($scope.diagnostic.beaconList.beacons[0]) {
									$scope.beacons = $scope.diagnostic.beaconList.beacons.toString();
									$scope.beaconsDetected = "Yes";
								}
							}
						}

						$scope.isCheckedIn = $scope.diagnostic.checkinOut_id ? "Check In" : "NOT Check In";

						if ($scope.isCheckedIn === "Check In") {
							$scope.reason = Remarks_EverthingIsFine;
						} else {
							if ($scope.beacons.length === 0) {
								$scope.reason = Remarks_NoBeaconDetected;
							}

							if ($scope.diagnostic.logtrace.includes('.17')
								|| $scope.diagnostic.logtrace.includes('.21')
								|| $scope.diagnostic.logtrace.includes('.23')) {
									$scope.reason = Remarks_CheckOut;
							}
						}

					}
				})
		};




		ctrl.getDiagnosticById();
    }
})();
