(function () {
    'use strict';
	
    angular.module('app').controller('OfferRedemptionController', OfferRedemptionController);

    OfferRedemptionController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function OfferRedemptionController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		
		$scope.redemption = {};
		$scope.redemptions = [];
		ctrl.dataLoading = true;
		
		ctrl.getRedemptions = function() {
			LaredoService.GetRedemptions("pending")
				.then(function(redemptions) {
					$scope.redemptions = redemptions;
					ctrl.dataLoading = false;
				});
		};
		
		ctrl.requestReason = function(id) {
			$scope.redemption = {
				id:id,
				status:'cancelled',
				change_reason:''
			};
			$('#reasonModal').modal();
		};
		
		ctrl.closeRedemption = function(id) {
			$scope.redemption = {id:id,status:'redeemed'};
			ctrl.saveRedemption();
		}
		
		ctrl.saveRedemption = function () {
			$scope.redemption.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.UpdateRedemption($scope.redemption)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
						ctrl.getRedemptions();
						$('#reasonModal').modal('hide');
					} else {
						FlashService.Error(response.message);
						ctrl.dataLoading = false;
					}
				});
		};
		
		ctrl.getRedemptions();
    }
})();