(function () {
    'use strict';

    angular.module('app').controller('ReferralCodeDetailController', ReferralCodeDetailController);

    ReferralCodeDetailController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function ReferralCodeDetailController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;


		
    }
})();