﻿(function () {
    'use strict';
    angular.module('app').factory('AuthenticationService', AuthenticationService);

    AuthenticationService.$inject = ['$http', '$cookieStore', '$rootScope', '$timeout', '$location', 'permissions'];
    function AuthenticationService($http, $cookieStore, $rootScope, $timeout, $location, permissions) {
        var service = {};

        service.Login = Login;
        service.SetCredentials = SetCredentials;
        service.ClearCredentials = ClearCredentials;

        return service;

        function Login(username, password, callback) {
            $http({
				method: 'POST',
				url: $rootScope.baseAPIUrl + '/employee/authenticate',
				data: { "username":username, "password":password},
				headers: {
					'Content-Type': 'application/json',
                    'Authorization': Base64.encode(username + ':' + password),
                    'x-api-key': process.env.X_API_KEY
				}
			}).then(
				function(response) {
					//console.log(response);
					callback(response);
				},
				function(response) {
					//console.log(response);
					callback(response);
				}
			);
        }

        function SetCredentials(username, user) {
            //var authdata = Base64.encode(username + ':' + password);
            $rootScope.globals = {
                currentUser: {
                    username: username,
                    authdata: user.auth,
                    name: user.name,
					id: user.id,
                    defaultLocation: user.defaultLoc,
                    permissions: user.employeePermissions,
                    companyId: user.company_id,
                    roleId: user.role_id
                },
                locations: user.locations,
                currentLocation: user.defaultLoc
            };

            permissions.setPermissions(user.employeePermissions);

            $http.defaults.headers.common['Authorization'] = 'Basic ' + user.auth; // jshint ignore:line
            $http.defaults.headers.common['x-api-key'] = process.env.X_API_KEY;
            $cookieStore.put('globals', $rootScope.globals);
        }

        function ClearCredentials() {
            $rootScope.globals = {};
			$rootScope.showhide = 'hide';
            $cookieStore.remove('globals');
            //$http.defaults.headers.common['Authorization'] = 'Basic';
        }
    }
})();