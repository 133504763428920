﻿
// for some reason the webpack ProvidePlugin isn't assigning the $ to the window properly, so we have to explicitly do it here
global.$ = require('jquery');
require('angular');
require('angular-cookies');
require('angular-route');
require('angular-ui-bootstrap');
require('ui-select');
require('angular-sanitize'); // for ui-select
require('ng-idle');
require('ngMap');
require('bootstrap');
require('bootstrap-ui-datetime-picker');
require('js-base64');
require('ng-file-upload');
require('ng-infinite-scroll');
require('textangular/dist/textAngular-sanitize.min');
require('textangular');
// require('angular-ui-grid/ui-grid.css');
// require('angular-ui-grid');
require('angular-ui-grid/ui-grid.core');
require('angular-ui-grid/ui-grid.tree-base');
require('angular-ui-grid/ui-grid.tree-view');
require('angular-ui-grid/ui-grid.cellnav');
require('angular-ui-grid/ui-grid.edit');
require('aws-sdk');


(function () {
    'use strict';

    function genEnum(enumDescriptor) {
        return Object.keys(enumDescriptor)
            .reduce(function(obj, cur) {
                var value = enumDescriptor[cur];
                obj[cur] = value.type;
                return obj;
            }, {});
	}

    function genDescriptionEnum(enumDescriptor) {
    	return Object.keys(enumDescriptor)
            .reduce(function(obj, cur) {
                var value = enumDescriptor[cur];
                obj[value.type] = value.desc || cur;
                return obj;
            }, {});
	}

    function genEnumOptions(enumDescriptor) {
        return Object.keys(enumDescriptor)
            .map(function(cur) {
                var value = enumDescriptor[cur];
                return Object.assign({}, value, {desc: value.desc || cur});
            });
    }

    var AccountFlags = {
        None: {type: 0, desc: 'Eligible'},
        Ineligible: {type: 1}, // e.g. location employee
        EligibilityVerified: {type: 2, desc: 'Eligibility Verified'},
        // Banned: {type: 3},
        // UserAppealed: {type: 4, desc: 'User Appealed'},
        // AwaitingUserResponse: {type: 5, desc: 'Awaiting User Response'},
        // Suspicious: {type: 6, desc: 'Suspicious'}
    };

	var MarketingTypes = {
		Unspecified: {type: 0},
		Email: {type: 1},
		Ambassador: {type: 2, desc: 'RCR Ambassador'},
		Referrer: {type: 3},
		Signage: {type: 4},
		InternetAd: {type: 5, desc: 'Internet Ad'},
		ServiceRecovery: {type: 6, desc: 'Service Recovery'}
	};

	var VerificationTypes = {
		Unverified: {type : 0, desc: 'Unverified'},
		Verified: {type : 1, desc: 'Verified'}
	};


	var NorthSouthTypes = {
		N: {type: 'N', desc: 'North'},
		S: {type: 'S', desc: 'South'},
	};



    angular
        .module('app', [
			'ngMap',
            'ngRoute', 'ngCookies', 'ngIdle',
			'ui.select', 'ngSanitize',
			'ui.bootstrap', 'ui.bootstrap.datetimepicker',
            'ngFileUpload', 'textAngular', 'infinite-scroll',
			'ui.grid', 'ui.grid.treeView', 'ui.grid.edit', 'ui.grid.cellNav'
        ])
        .value('THROTTLE_MILLISECONDS', 250) // for infinitescroll
		.value('AppEnumOptions', {
			AccountFlag: genEnumOptions(AccountFlags),
            MarketingType: genEnumOptions(MarketingTypes),
			VerificationFlag: genEnumOptions(VerificationTypes),
			NorthSouthType: genEnumOptions(NorthSouthTypes),
		})

		.value('AppEnums', {
            AccountFlag: genEnum(AccountFlags),
			MarketingType: genEnum(MarketingTypes),
            AdHocMessageType: {
                Push: 0,
                Email: 1
            },


		})
		.value('AppConstants', {
			GroupMessageCriteria:  [
				{"value": 0, "description" : "Adhoc"},
				{"value": 1, "description" : "Send message to users that are currently checked in"},
				{"value": 2, "description" : "Send message to users that are NOT currently checked in"},
				{"value": 3, "description" : "Send message to users that are currently checked in within <criteria value> number of days."},
				{"value": 4, "description" : "Send message to users that are NOT currently checked in within <criteria value> number of days."},
			],
		})
		.value('AppEnumDescriptions', {
			AccountFlag: genDescriptionEnum(AccountFlags),
            MarketingType: genDescriptionEnum(MarketingTypes)
		})
		.config(config)
        .run(run)
		.filter('bool_convert', function() {
			return function(text, length, end) {
				return (text ? 'yes' : 'no');
			}
		})
		.factory('permissions', ['$rootScope', function ($rootScope) {
			var permissionList;
			return {
				setPermissions: function(permissions) {
					//console.log(permissions);
					permissionList = permissions;
					$rootScope.$broadcast('permissionsChanged');
				},
				hasPermission: function (permission) {
					if(!permission)
						return true;

					if(!permissionList)
						return false
					else
						return permissionList.indexOf(permission.trim()) > -1;
				},
				getPermissions: function () {
					return permissionList;
				}
			};
		}])
		.directive('restpin', function () {
			return {
				restrict: 'AE',
				template: require('./views/partials/ResetPin.html')
			}
		})
		.directive('offerdetail', function () {
			return {
				restrict: 'AE',
				scope: {
					offer: '='
				},
				template: require('./views/partials/OfferDetails.html')
			}
		})
		.directive('equals', function () {
			return {
				restrict: 'A',
		        require: "ngModel",
		        scope: {
		            otherModelValue: "=equals"
		        },
		        link: function(scope, element, attributes, ngModel) {

		            ngModel.$validators.equals = function(modelValue) {
		                return modelValue == scope.otherModelValue;
		            };

		            scope.$watch("otherModelValue", function() {
		                //ngModel.$validate();
		            });
		        }
		    };
		})
		.directive('ngReallyClick', ['$uibModal', function($uibModal) {
				return {
					restrict: 'A',
					scope: {
						ngReallyClick:"&ngReallyClick"
					},
					link: function($scope, element, attrs) {
						element.bind('click', function() {
							$scope.modalMessage = attrs.ngReallyMessage || "Are you sure?";

							var uibModalInstance = $uibModal.open({
								template: require('./views/partials/ConfirmModal.html'),
								controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                                    $scope.ok = function() {
                                        $uibModalInstance.close();
                                    };

                                    $scope.cancel = function() {
                                        $uibModalInstance.dismiss('cancel');
                                    };
                                }],
								scope: $scope,
								transclude:true,
								size:'sm'
							});

							uibModalInstance.result.then(
								function() {
									$scope.ngReallyClick();
								}, function() {
									//console.log("hit cancel");
								}
							);
						});
					}
				}
			}
		])
		.directive('hasPermission', ['permissions', function(permissions) {
				return {
					link: function(scope, element, attrs) {
						if(!attrs.hasPermission) {
							throw 'hasPermission value must be a string'
						}
						var value = attrs.hasPermission.trim();
						var notPermissionFlag = value[0] === '!';
						if(notPermissionFlag) {
							value = value.slice(1).trim();
						}

						function toggleVisibilityBasedOnPermission() {
							var hasPermission = permissions.hasPermission(value);
							if(hasPermission && !notPermissionFlag || !hasPermission && notPermissionFlag) {
							  	element.show();
							}
							else {
							  element.hide();
							}
						}

						toggleVisibilityBasedOnPermission();
						scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
					}
				};
			}]
		)
		.directive('dropdown', ['LaredoService', function(LaredoService, $scope) {
			return {
				restrict: 'AE',
				scope: true,
				link: function(scope, element, attrs) {
					element.bind("change", function(e) {
						var idx = e.target.selectedIndex;
						scope.$root.globals.currentLocation = e.target.options[idx].value;

						scope.$apply();

						scope.$emit('changeCurrentLocation_app', {locationId: scope.$root.globals.currentLocation});

						/*LaredoService.GetAccountsCheckedIn( scope.$root.globals.currentLocation )
							.then(function(accounts) {
								scope.$root.checkedInCount=accounts.length;
							});*/
					});
				}
			};
		}]);

    config.$inject = ['$routeProvider', '$locationProvider', 'IdleProvider', 'KeepaliveProvider', 'TitleProvider'];
    function config($routeProvider, $locationProvider, IdleProvider, KeepaliveProvider, TitleProvider) {
		IdleProvider.idle(15 * 60);
		IdleProvider.timeout(30);
		KeepaliveProvider.interval(30);
		TitleProvider.enabled(false);

        $routeProvider
            .when('/players', {
                controller: 'HomeController',
                template: require('./views/players.html'),
                controllerAs: 'ctrl',
                permission: 'view_accounts'
            })

			.when('/players/view/:playerId', {
				controller: 'PlayerViewController',
				template: require('./views/playerview.html'),
				controllerAs: 'ctrl',
                permission: 'view_accounts'
			})

			.when('/players/:playerId', {
				controller: 'HomeController',
				template: require('./views/playeredit.html'),
				controllerAs: 'ctrl',
                permission: 'edit_accounts'
			})

			.when('/enroll', {
				controller: 'HomeController',
				template: require('./views/enroll.html'),
				controllerAs: 'ctrl',
                permission: 'create_account'
			})

			.when('/checkins', {
				controller: 'HomeController',
				template: require('./views/checkins.html'),
				controllerAs: 'ctrl',
                permission: 'view_accounts'
			})

			.when('/locations', {
				controller: 'LocationController',
				template: require('./views/locations.html'),
				controllerAs: 'ctrl',
                permission: 'view_locations'
			})

			.when('/locations/:locationId', {
				controller: 'LocationController',
				template: require('./views/locationedit.html'),
				controllerAs: 'ctrl',
                permission: 'edit_locations'
			})

			.when('/locations/:locationId/view', {
				controller: 'LocationController',
				template: require('./views/locationview.html'),
				controllerAs: 'ctrl',
                permission: 'view_locations'
			})

			// .when('/questions', {
			// 	controller: 'QuestionController',
			// 	template: require('./views/questions.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'view_questions'
			// })
			//
			// .when('/questions/:questionId', {
			// 	controller: 'QuestionController',
			// 	template: require('./views/questionedit.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'edit_questions'
			// })
			//
			// .when('/questions/new', {
			// 	controller: 'QuestionController',
			// 	template: require('./views/questionedit.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'edit_questions'
			// })

            .when('/login', {
                controller: 'LoginController',
                template: require('./views/login.view.html'),
                controllerAs: 'ctrl',
                permission: ''
            })

			.when('/employees', {
				controller: 'EmployeeController',
				template: require('./views/employees.html'),
				controllerAs: 'ctrl',
				permission: 'view_employees'
			})

			.when('/employees/:employeeId', {
				controller: 'EmployeeController',
				template: require('./views/employeeedit.html'),
				controllerAs: 'ctrl',
				permission: 'edit_employees'
			})

			.when('/employees/:employeeId/reset', {
				controller: 'ResetPasswordController',
				template: require('./views/empreset.html'),
				controllerAs: 'ctrl',
				permission: ''
			})

			.when('/companies', {
				controller: 'CompanyController',
				template: require('./views/companylist.html'),
				controllerAs: 'ctrl',
                permission: 'view_companies'
			})

			.when('/companies/:companyId', {
				controller: 'CompanyController',
				template: require('./views/companyedit.html'),
				controllerAs: 'ctrl',
                permission: 'edit_companies'
			})

			.when('/roles', {
				controller: 'RoleController',
				template: require('./views/rolelist.html'),
				controllerAs: 'ctrl',
                permission: 'view_roles'
			})

			.when('/roles/:roleId', {
				controller: 'RoleController',
				template: require('./views/roleedit.html'),
				controllerAs: 'ctrl',
                permission: 'edit_roles'
			})

			.when('/permissions', {
				controller: 'PermissionController',
				template: require('./views/permissionlist.html'),
				controllerAs: 'ctrl',
                permission: 'view_permissions'
			})

			// .when('/prizetypes', {
			// 	controller: 'PrizeTypeController',
			// 	template: require('./views/prizetypes.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'view_prizetypes'
			// })
			//
			// .when('/prizes', {
			// 	controller: 'PrizeController',
			// 	template: require('./views/prizes.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'view_prizes'
			// })
			//
			// .when('/prizes/:prizeId', {
			// 	controller: 'PrizeController',
			// 	template: require('./views/prizeedit.html'),
			// 	controllerAs: 'ctrl',
            //     permission: 'edit_prizes'
			// })

            .when('/referrals', {
                controller: 'ReferralCodeListController',
                template: require('./views/referralCodes-list.html'),
                controllerAs: 'ctrl',
                permission: 'view_offers'
            })

            .when('/referrals/:referralId', {
                controller: 'ReferralCodeDetailController',
                template: require('./views/referralCodes-detail.html'),
                controllerAs: 'ctrl',
                permission: 'view_offers'
            })

            .when('/referrals/:referralId/edit', {
                controller: 'ReferralCodeEditController',
                template: require('./views/referralCodes-edit.html'),
                controllerAs: 'ctrl',
                permission: 'edit_offers'
            })

			/*.when('/offers', {
				controller: 'OfferController',
				template: require('./views/offers.html'),
				controllerAs: 'ctrl',
                permission: 'view_offers'
			})

			.when('/offers/:offerId', {
				controller: 'OfferController',
				template: require('./views/offeredit.html'),
				controllerAs: 'ctrl',
                permission: 'edit_offers'
			})

			.when('/offers/view/:offerId', {
				controller: 'OfferViewController',
				template: require('./views/offerview.html'),
				controllerAs: 'ctrl',
				permission: 'view_offers'
			})*/

			/*.when('/redemptions', {
				controller: 'OfferRedemptionController',
				template: require('./views/offerredemptions.html'),
				controllerAs: 'ctrl',
                permission: ''
			})*/

			.when('/drawings', {
				controller: 'DrawingController',
				template: require('./views/drawings.html')
			})

			.when('/drawings/view/:drawingId', {
				controller: 'DrawingViewController',
                controllerAs: 'ctrl',
				template: require('./views/drawingview.html')
			})

			.when('/drawings/view/:drawingId/date/:dateId', {
				controller: 'DrawingViewReportController',
                controllerAs: 'ctrl',
				template: require('./views/drawingreportview.html')
			})

			.when('/drawings/:drawingId', {
				controller: 'DrawingEditController',
                controllerAs: 'ctrl',
				template: require('./views/drawingedit.html')
			})

			.when('/drawingWinnersEmail', {
				controller: 'DrawingWinnersEmailController',
                controllerAs: 'ctrl',
				template: require('./views/drawingWinnerEmails.html'),
				permission: 'admin'
			})


			.when('/notifications', {
				controller: 'NotificationController',
				template: require('./views/notifications.html'),
                controllerAs: 'ctrl',
			})

			.when('/appconfigs', {
				controller: 'AppConfigController',
				template: require('./views/appconfigs.html'),
				controllerAs: 'ctrl',
                permission: 'admin'
			})

			.when('/appversions', {
				controller: 'AppVersionsController',
				template: require('./views/appversions.html'),
				controllerAs: 'ctrl',
                permission: 'admin'
			})

			.when('/privacy-policy', {
				controller: 'HomeController',
				template: require('./views/privacypolicy.html')
			})

			.when('/unauthorized', {
				controller: 'HomeController',
				template: require('./views/unauthorized.html')
			})

			.when('/diagnostics', {
				controller: 'DiagnosticController',
				template: require('./views/diagnostics.html'),
				controllerAs: 'ctrl'
			})

			.when('/diagnostics/:diagnosticId', {
				controller: 'DiagnosticDetailController',
				template: require('./views/diagnosticview.html'),
				controllerAs: 'ctrl'
			})


            .otherwise({ redirectTo: '/players' });
    }

    run.$inject = ['$rootScope', '$location', '$cookieStore', '$http', 'Idle', 'permissions', '$interval'];
    function run($rootScope, $location, $cookieStore, $http, Idle, permissions, $interval) {
        // keep user logged in after page refresh
        $rootScope.globals = $cookieStore.get('globals') || {};

        if($rootScope.globals.currentUser) {
        	permissions.setPermissions($rootScope.globals.currentUser.permissions);
			$http.defaults.headers.common['Authorization'] = 'Basic ' + $rootScope.globals.currentUser.authdata;
        	$http.defaults.headers.common['x-api-key'] = process.env.X_API_KEY;
        }


		$rootScope.baseAPIUrl = process.env.BASEURL;


		/*****  IDLE TIMEOUT LOGIC  *****/
		$rootScope.$on('IdleStart', function() {
			$('#timeoutModal').modal();

		});
		$rootScope.$on('IdleWarn', function(e, countdown) {
			$rootScope.idleCount = countdown;
			$('#idleCount').text(countdown);

		});

		$rootScope.$on('IdleTimeout', function() {
			$('#timeoutModal').modal('hide');
			document.location.href = '/#/login';
		});

		$rootScope.$on('Keepalive', function() {
			$('#timeoutModal').modal('hide');
		});

		$rootScope.$on('$routeChangeStart', function(scope, next, current) {
			var permission = next.$$route.permission;
			if(permission && !permissions.hasPermission(permission)) {
				$location.path('/unauthorized');
			}

		});

        $rootScope.$on('$locationChangeStart', function (event, next, current) {

			if($location.path() === '/privacy-policy'){
				return
			}
			if(current != next) {
				$rootScope.lastPage = current.split('#')[1];
			} else {
				$rootScope.lastPage = '/players';
			}

            // redirect to login page if not logged in and trying to access a restricted page
            var restrictedPage = $.inArray($location.path(), ['/login']) === -1;
            var loggedIn = $rootScope.globals.currentUser;
			Idle.watch();
            if (restrictedPage && !loggedIn) {
                $location.path('/login');
            }
        });

		$http({method:'GET', url:'resources/app.properties'}).then(function(response) {
			$rootScope.version = response.data.version;
			$rootScope.states = response.data.states;
		});

		$interval(function () {
			var locationId = (permissions.hasPermission('is_admin') ? '' : $rootScope.globals.currentLocation);
			$http.get($rootScope.baseAPIUrl + '/checkins?locationId=' + locationId).then(
				function(response) {
					$rootScope.checkedInCount=response.data.length;
				},
				function(response) {
					$rootScope.checkedInCount=0;
				}
			);
        }, 60000);

    }
})();

function check (input) {
	if(input.value <= 1)
		input.value = input.value.trim();
}

require('./services/rcrHttp.service');
require('./services/authentication.service');
require('./services/flash.service');
require('./services/laredo-service');
require('./services/referralCodes.service');

require('./controllers/company.controller');
require('./controllers/drawingWinnersEmail.controller');
require('./controllers/role.controller');
require('./controllers/permission.controller');
require('./controllers/employeecontroller');
require('./controllers/empresetcontroller');
require('./controllers/home.controller');
require('./controllers/playerview.controller');
require('./controllers/question.controller');
require('./controllers/location.controller');
require('./controllers/prizetype.controller');
require('./controllers/prize.controller');
require('./controllers/offer.controller');
require('./controllers/offerview.controller');
require('./controllers/offerredemption.controller');
require('./controllers/login.controller');
require('./controllers/notification.controller');
require('./controllers/drawing.controller');
require('./controllers/appconfig.controller');
require('./controllers/appversions.controller');

require('./controllers/referralCode-list.controller');
require('./controllers/referralCode-detail.controller');
require('./controllers/referralCode-edit.controller');

require('./controllers/diagnostic.controller');
