(function () {
    'use strict';

	angular.module('app').filter('prizeFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.description) || re.test(obj.prizeTypeTitle) || re.test(obj.modifyName) || re.test(obj.offerTitle) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});
	
    angular.module('app').controller('PrizeController', PrizeController);

    PrizeController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function PrizeController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		
		$scope.prize = {};
		$scope.prizes = [];
		$scope.prizeTypes = [];
		$scope.offers = [];
		//$scope.loading = (ctrl.dataLoading || true ? "show" : "hide");
		
		ctrl.getPrizes = function() {
			LaredoService.GetPrizes()
				.then(function(prizes) {
					$scope.prizes = prizes;
				});
		};
		
		ctrl.getPrizeById = function (id) {
			LaredoService.GetPrizeById(id)
				.then(function(prize) {
					$scope.prize = prize;
					//console.log(prize);
				});
		};
		
		ctrl.savePrize = function () {
			$scope.prize.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.SavePrize($scope.prize)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
						$location.path('/prizes');
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error(response.message);
					}
				});
		};
		
		ctrl.deletePrize = function (id) {
           LaredoService.DeletePrize(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        ctrl.getPrizes();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
					}
				});
        };
		
		ctrl.getPrizeTypes = function() {
			LaredoService.GetPrizeTypes()
				.then(function(prizeTypes) {
					$scope.prizeTypes = prizeTypes;
				});
		};
		
		ctrl.getOffers = function() {
			LaredoService.GetOffers()
				.then(function(offers) {
					$scope.offers = offers;
				});
		};
		
		if($routeParams.prizeId && $routeParams.prizeId.length > 0) {
			if($routeParams.prizeId > -1)
				ctrl.getPrizeById($routeParams.prizeId);
			else 
				$scope.prize = {};
			ctrl.getPrizeTypes();
			ctrl.getOffers();
		} else {
			ctrl.getPrizes();
		}
		
    }
})();