(function () {
    'use strict';
	
	angular.module('app').filter('roleFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.name) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});

    angular.module('app').controller('RoleController', RoleController);

    RoleController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams','$rootScope'];
    function RoleController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		
		$scope.role = {role_permissions:[]};
		$scope.roles = [];
		$scope.permissions = [];
		
		$scope.toggleSelection = function (locId) {
			var idx = $scope.role.role_permissions.indexOf(locId);

			if (idx > -1) {
				$scope.role.role_permissions.splice(idx, 1);
			} else {
				$scope.role.role_permissions.push(locId);
			}
		};
		
		ctrl.getPermissions = function() {
			LaredoService.GetPermissions()
				.then(function(permissions) {
					$scope.permissions = permissions;
				});
		};
		
		ctrl.getRoles = function() {
			LaredoService.GetRoles()
				.then(function(roles) {
					$scope.roles = (roles.success == false ? [] : roles);
				});
		};
		
		ctrl.getRoleById = function (id) {
			LaredoService.GetRoleById(id)
				.then(function(role) {
					$scope.role = role;
					//console.log(role);
				});
		};
		
		ctrl.saveRole = function () {
			//$scope.role.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.SaveRole($scope.role)
			.then(function(response) {
				if(response.status == "200") {
                    $location.path('/roles');
				} else {
					
				}
			});
		};
		
		ctrl.deleteRole = function (id) {
           LaredoService.DeleteRole(id)
				.then(function(response) {
					if(response.status == "200") {
                        ctrl.getRoles();
					} else {
						
					}
				});
        };
		
		if($routeParams.roleId && $routeParams.roleId.length > 0) {
			if($routeParams.roleId > -1)
				ctrl.getRoleById($routeParams.roleId);
			ctrl.getPermissions();
		} else {
			ctrl.getRoles();
		}
		
    }
})();