var EntryType = {
    Normal: 0,
    Bonus: 1
};

var EntryStatus = {
    Valid: 0,
    Invalidated: 1,
    PendingApprovalThreshold: 2,
    PendingApprovalLocation: 3
};

function entryStatusDesc(status) {
    switch (status) {
        case EntryStatus.Valid: return 'Valid';
        case EntryStatus.Invalidated: return 'Invalidated';
        case EntryStatus.PendingApprovalThreshold: return 'Pending (Threshold)';
        case EntryStatus.PendingApprovalLocation: return 'Pending (Location)';
        default: return undefined;
    }
}

var CheckinsPageSize = 100;

(function () {
    'use strict';

    angular.module('app')
		.controller('PlayerViewController', PlayerViewController)
        .filter('display_status', ['$filter', function($filter) {
            return function(obj, length, end) {
                var msg = "";
                if(!obj.status) {
                    msg = (obj.checkInCount > 0 || obj.type == 'pickgame' ? "Offer is available" : "Player must check in for offer to be available.");
                } else if (obj.status == 'open') {
                    msg = "Offer is available";
                } else if (obj.status == 'closed') {
                    msg = "Player didn't choose the winner";
                } else if (obj.status == 'pending') {
                    msg = "Player receives: " + obj.description;
                } else if (obj.status == 'redeemed') {
                    msg = "Player received '" + obj.description + "' from " + obj.modifyName + " on " + $filter('date')(obj.modifyDate, "MMM dd, yyyy 'at' h:mma");
                } else if (obj.status == 'cancelled') {
                    msg = "Offer was cancelled";
                } else if (obj.status == 'expired') {
                    msg = "Offer expired";
                }

                return msg;
            };
        }])
        .filter('entryStatus', function() {
            return function(status) {
                return entryStatusDesc(status) || status;
            };
        });

    PlayerViewController.$inject = ['AppEnums', 'AppEnumDescriptions', 'LaredoService', 'FlashService', '$rootScope', '$location', '$routeParams', '$scope', 'permissions'];
    function PlayerViewController(AppEnums, AppEnumDescriptions, LaredoService, FlashService, $rootScope, $location, $routeParams, $scope, permissions) {
        var ctrl = this;

        ctrl.AccountFlag = AppEnums.AccountFlag;
        ctrl.AccountFlagDesc = AppEnumDescriptions.AccountFlag;

		$scope.player = {};
		$scope.offers = [];
		$scope.pagedOffers = [];
		$scope.redemptions = [];
		$scope.locations = [];
		$scope.redemption = {};
		$scope.predicate = 'time_in';
		$scope.reverse = true;
		$scope.order = function(predicate) {
			$scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
			$scope.predicate = predicate;
		};

        $scope.showPin = false;
		$scope.currentPage = 1;
		$scope.currentRedemptionPage = 1;
		$scope.numPerPage = 5;
		ctrl.dataLoading = true;


        $scope.toggleShowPin = function() {
            $scope.showPin = !$scope.showPin;
            if($scope.showPin && !$rootScope.player.unencrypted_pin_number){
                $scope.player.unencrypted_pin_number = Base64.decode($scope.player.pin_number);
            }
        }

		$scope.toggleModal = function() {
			if(!$rootScope.player.opt_in == 0)
				$('#myModal').modal();
		};

		ctrl.setOffer = function(offerEl) {
			$scope.offer = offerEl;
		};

		ctrl.getPlayerById = function (id) {
			LaredoService.GetAccountById(id)
				.then(function (player) {
					player.opt_in = (player.opt_in == 1 ? true : false);
					player.confirm_age = (player.confirm_age == 1 ? true : false);
					player.confirm_sep = (player.confirm_sep == 1 ? true : false);
                    player.user_agent_os = (player.user_agent_os == null ? "NA" : player.user_agent_os);
                    player.user_agent_build_no = (player.user_agent_build_no == null ? "NA" : player.user_agent_build_no);
                    $scope.player = player;
					ctrl.dataLoading = false;
				});
		};

		ctrl.getAccountCheckins = function (accountId, rowcount) {
			LaredoService.GetAccountCheckinsDetail(accountId, rowcount)
				.then(function(records) {
					setCheckinTableData(records);
                });
		};

		// ctrl.getPlayerOffers = function(id) {
		// 	LaredoService.GetAccountOffers(id)
		// 		.then(function(offers) {
		// 			$scope.offers = offers;
		// 		});
		// };

		ctrl.getPlayerRedemptions = function(id) {
			LaredoService.GetRedemptions(id, false)
				.then(function(redemptions) {
					$scope.redemptions = redemptions;
				});
		};

		ctrl.getLocations = function () {
			LaredoService.GetAllLocations(permissions)
				.then(function(locations) {
					ctrl.locations = locations;
				});
		};

		ctrl.requestReason = function(id) {
			$scope.redemption = { id:id, status:'cancelled', change_reason:'' };
			$('#reasonModal').modal();
		};

		ctrl.closeRedemption = function(id) {
			$scope.redemption = {id:id,status:'redeemed', change_reason: null};
			//  PIN IS NO LONGER REQUIRED
			/*
			ctrl.getLocations();
			$('#redemptionModal').modal();
			*/
			ctrl.saveRedemption();
		}

		ctrl.saveRedemption = function () {
			$scope.redemption.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.UpdateRedemption($scope.redemption)
				.then(function(response) {
					if(response.status == "200") {
						ctrl.getPlayerOffers($routeParams.playerId);
						ctrl.getPlayerRedemptions($routeParams.playerId);
						$('#redemptionModal').modal('hide');
					} else {
						ctrl.dataLoading = false;
					}
				});
		};

		// ctrl.getPlayerEntries = function () {
		// 	LaredoService.GetEntriesForAccount($routeParams.playerId).then(function (response) {
		// 		//console.log("Entries: " + JSON.stringify(response));
		// 		$scope.entries = response;
		// 		for(var i=0; i < $scope.entries.length; i++) {
		// 			$scope.entryTotal += $scope.entries[i].entryCount;
		// 		}
		// 	});
		// };

		// ctrl.addEntry = function (locationId) {
		// 	var entryObj = {
		// 		"account_id": $routeParams.playerId,
		// 		"location_id": locationId
		// 	};
		// 	LaredoService.AddEntry(entryObj).then(function (response) {
		// 		ctrl.getPlayerEntries();
		// 	});
		// };
        //
		// ctrl.removeEntry = function (locationId) {
		// 	LaredoService.DeleteEntry($routeParams.playerId, locationId).then(function (response) {
		// 		ctrl.getPlayerEntries()
		// 	});
		// };

        var statusField = 'status';
        var columnDefs = [
            { name: 'locationName', displayName: 'Location', width: '25%' },
            { name: 'entries', displayName: 'Entries', width: '10%' },
            {
                name: statusField,
                displayName: 'Status',
				cellFilter: 'entryStatus',
                width: '20%',
				// NOTE: if we just enable edit on this column and not the whole table then we get the weird behavior that only every other row is editable (ui-grid BUG)
                // enableCellEdit: true,
                // enableCellEditOnFocus: true,
                editableCellTemplate: 'ui-grid/dropdownEditor',
                editDropdownValueLabel: 'label',
                editDropdownOptionsArray: Object.keys(EntryStatus).map(function(key) {
                	var statusCode = EntryStatus[key];
                	return {id: statusCode, label: entryStatusDesc(statusCode)}
                }),
                cellEditableCondition: function($scope, triggerEvent) {
                    return !!$scope.row.entity.entryId;
                }
            },
            { name: 'time_in', displayName: 'Time In', cellFilter: 'date:"MM/dd/yy h:mm a"', width: '20%' },
            { name: 'time_out', displayName: 'Time Out', cellFilter: 'date:"MM/dd/yy h:mm a"', width: '20%' }
        ];

        $scope.gridOptions = {
        	enableCellEditOnFocus: true,
        	enableColumnMenus: false,
            enableSorting: false, // disable until we can test if it affects the entry children
			enableExpandAll: false, // disable because it causes child entry loading issue
            // enableFiltering: true,
            showTreeExpandNoChildren: true,
            columnDefs: columnDefs.map(function(def) { return Object.assign(def, {enableCellEdit: def.name === statusField})}),
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.treeBase.on.rowExpanded($scope, function(row) {
					// console.log(row);
                    if (!row.entity.$expanded /*&& !$scope.nodeLoaded*/) {
                        LaredoService.GetCheckinEntries(row.entity.checkinId).then(function(entries) {
                            var data = $scope.gridOptions.data;

                        	var startingIndex = row.entity.$idx + 1;
                            Array.prototype.splice.apply(data, [startingIndex, 0].concat(
                            	entries.map(function(entry, idx) {
                                    var entries = entry.amount;
                                    if (entry.entryType === EntryType.Bonus) {
                                    	entries += ' (Bonus)';
									}
                            		return {
                                    	entryId: entry.id,
                                        entries: entries,
                                        status: entry.status,
                                        time_in: entry.earningBegan,
                                        time_out: entry.earned,
                                        // $idx: startingIndex + idx
										// leave $$treelevel undefined
                                    };
                                })
							));


                            for (var i = startingIndex; i < data.length; i++) {
                                data[i].$idx = i;
							}

                            // $scope.nodeLoaded = true;
                        });
                        row.entity.$expanded = true; // for now we'll only let it load once
                    }
                });

                gridApi.edit.on.afterCellEdit($scope, function(rowEntity, colDef, newValue, oldValue) {
                	if (rowEntity.entryId && newValue != oldValue) {
                        // console.log('edited entry id:' + rowEntity.entryId + ' Column:' + colDef.name + ' newValue:' + newValue + ' oldValue:' + oldValue);
                        LaredoService.UpdateEntryStatus(rowEntity.entryId, {status: newValue})
							.then(function(response) {
								if (response) {
                                    updateTotalEntries();
                                }
							})
							.catch(function(err) {
								rowEntity.status = oldValue;
								FlashService.Error("We're sorry, there was an error updating entry '" + rowEntity.entryId + "'.");
							});
					}
                });
            }
        };

        function setCheckinTableData(checkins) {
            $scope.gridOptions.data = checkins.map(function(entry, idx) {
                return Object.assign(entry, {
                    $$treeLevel: 0,
                    $idx: idx
                });
            });
        }

		var accountId = $routeParams.playerId;
		ctrl.getPlayerById(accountId);
		ctrl.getAccountCheckins(accountId, CheckinsPageSize);
		// ctrl.getPlayerOffers(accountId);
		ctrl.getPlayerRedemptions(accountId);
		// ctrl.getPlayerEntries();

        LaredoService.GetAccountCheckins(accountId, 1, true)
            .then(function(records) {
                ctrl.firstCheckinLocation = records && records.length
                    ? records[0].locationName
                    : '*Has never checked in*';
            });

		function updateTotalEntries() {
            return Promise.all([
                LaredoService.GetTotalEntriesForCurrentDrawing(accountId)
                    .then(function(response) {
                        if(response.allSessions && response.allSessions.length ){
                            ctrl.currentDrawingEntries = response.allSessions[0].entryCount;
                        }else{
                            ctrl.currentDrawingEntries = 0;
                        }
                    }),
				LaredoService.GetTotalEntriesForCurrentDrawing(accountId, true)
					.then(function(response) {
                        if(response.allSessions && response.allSessions.length > 0){
                            ctrl.currentDrawingFlaggedEntries = response.allSessions[0].entryCount;
                        }else{
                            ctrl.currentDrawingFlaggedEntries = 0;
                        }

					})
			]);
		}
		updateTotalEntries();
	}
})();
