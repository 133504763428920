(function () {
    'use strict';

    angular.module('app')
    	.directive('offerFrequency', function () {
			return {
				restrict: 'EA',
				scope: {
					val:"="
				},
		        link: function(scope, element, attributes) {
		        	var displayVal = "";
		        	scope.$watch("val", function() {
						if(scope.val.type) {
							if(scope.val.frequency == 'acquisition') {
				            	displayVal = "Acquisition";
				            } else if (scope.val.frequency == 'daily') {
				            	displayVal = "Daily";
				            } else if (scope.val.frequency == 'onetime') {
				            	displayVal = "One Time";
				            }

				            element[0].innerHTML = displayVal;
						}
		            });
		            //console.log("frequency: " + scope.val);
		        }
		    };
		})
		.directive('offerType', function () {
			return {
				restrict: 'EA',
				scope: {
					val:'='
				},
		        link: function(scope, element, attrs) {
		        	var displayVal = "";
		        	
					var types = [
						{'id':'kioskgame','name':'Kiosk Game'},
						{'id':'pickgame','name':'Sports Pick Game'},
						{'id':'earn','name':'Earn and Get'},
						{'id':'progressive', 'name':'Progressive'},
						{'id':'hotseat', 'name':'Hot Seat'},
						{'id':'other','name':'Other'}
					];

					scope.$watch("val", function() {
						if(scope.val.type) {
							for(var i in types) {
								if(scope.val.type.trim() == types[i].id.trim()) {
				            		displayVal = types[i].name;
				            		break;
				            	}
				            		
				            }
				            element[0].innerHTML = displayVal;
						}
		            });
		        }
		    };
		});

    angular.module('app').controller('OfferViewController', OfferViewController);

    OfferViewController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function OfferViewController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;

		$scope.offer = {};
		
		ctrl.getWinners = function(id) {
			LaredoService.GetWinners(id)
				.then(function(winners) {
					$scope.winners = winners;
				});
		};

		ctrl.getPrizes = function(id) {
			/*  companyId, offerId  */
			LaredoService.GetPrizes(null, id) 
				.then(function(prizes) {
					$scope.prizes = prizes;
				});
		};
		
		ctrl.getOfferById = function (id) {
			LaredoService.GetOfferById(id)
				.then(function(offer) {
					offer.start_date = new Date(offer.start_date);
					offer.end_date = new Date(offer.end_date);
					offer.redeem_by = new Date(offer.redeem_by);
					$scope.offer = offer;
					$scope.accounts = offer.accounts;
				});
		};

		ctrl.getOfferById($routeParams.offerId);
		ctrl.getPrizes($routeParams.offerId);
		ctrl.getWinners($routeParams.offerId);
		
    }
})();