(function () {
    'use strict';

	angular.module('app').filter('appVersionsFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.platform) || re.test(obj.build) || re.test(obj.version) || re.test(obj.url)) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});
	
    angular.module('app').controller('AppVersionsController', AppVersionsController);

    AppVersionsController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope', 'textAngularManager'];
    function AppVersionsController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope, textAngularManager) {
        var ctrl = this;
		
		$scope.appVersion = {};
		$scope.appVersions = [];
		$scope.platformOptions = [
			"Android",
			"iOS"
		]

		ctrl.platformOptions = $scope.platformOptions;
		
		ctrl.getAppVersions = function() {
			LaredoService.GetAppVersions()
				.then(function(versions) {
					$scope.appVersions = versions;
				});
		};
		
		ctrl.getAppVersionById = function (id) {
			LaredoService.GetAppVersionById(id)
				.then(function(version) {
					$scope.appVersion = version;
				});
		};
		
		ctrl.editAppVersion = function (id) {
			if(id) {
				$scope.appVersion = ctrl.getAppVersionById(id);
			} else {
				$scope.appVersion = {};
			}
			
			$('#appVersionModal').modal();
		};
		
		ctrl.saveAppVersion = function () {
			LaredoService.SaveAppVersion($scope.appVersion).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
					ctrl.getAppVersions();
					$('#appVersionModal').modal('hide');
				} else {
					FlashService.Error(response.message);
				}
			});
		};
		
		ctrl.deleteAppVersion = function (id) {
           	LaredoService.DeleteAppVersion(id).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
                    ctrl.getAppVersions();
				} else {
					FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
					ctrl.dataLoading = false;
				}
			});
        };
		
		ctrl.getAppVersions();
    }
})();