(function () {
    'use strict';

    angular.module('app').controller('ReferralCodeListController', ReferralCodeListController);

    ReferralCodeListController.$inject = ['ReferralCodeService', 'LaredoService', 'FlashService', '$scope', '$rootScope'];
    function ReferralCodeListController(ReferralCodesService, LaredoService, FlashService, $scope, $rootScope) {
        var ctrl = this;
        var referralsPage = 0;

        ctrl.referrals = [];

        ctrl.loadReferrals = loadReferrals;
        ctrl.getMarketingType = ReferralCodesService.getMarketingType;

        function resetPlayers() {
            referralsPage = 0;
            ctrl.referrals = [];
        }

        function loadReferrals(reset) {
            if (reset) {
                resetPlayers();
            }

            ReferralCodesService.getAll($scope.query || '', referralsPage++)
                .then(function(referrals) {
                    ctrl.referrals = ctrl.referrals.concat(referrals);
                    console.log("Referrals : ", ctrl.referrals);

                });
        }
    }
})();