﻿(function () {
    'use strict';

	angular.module('app').filter('playerFilter', function() {
		return function(input, query) {
			var out = [];

			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.first_name + ' ' + obj.last_name) || re.test(obj.email_address) || re.test(obj.phone_number) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});

    angular.module('app').controller('HomeController', HomeController);

    HomeController.$inject = ['AppEnums', 'AppEnumOptions', 'AppEnumDescriptions', 'LaredoService', 'FlashService', '$rootScope', '$location', '$routeParams', '$scope', 'permissions'];
    function HomeController(AppEnums, AppEnumOptions, AppEnumDescriptions, LaredoService, FlashService, $rootScope, $location, $routeParams, $scope, permissions) {
        var ctrl = this;

        ctrl.AccountFlag = AppEnums.AccountFlag;
        ctrl.AccountFlagDesc = AppEnumDescriptions.AccountFlag;
        ctrl.AccountFlagOptions = AppEnumOptions.AccountFlag;
		ctrl.AccountVerificationOptions = AppEnumOptions.VerificationFlag;

        ctrl.user;
		$rootScope.player = {};
		$scope.checkinRecord = {"endpoint_type": "admin"};
		$scope.showPin = false;

		$scope.$on('changeCurrentLocation_app' , function (event, obj){
			$scope.$broadcast('currentLocationChange', obj);

		});


		$scope.toggleShowPin = function() {
			$scope.showPin = !$scope.showPin;
			if($scope.showPin && !$rootScope.player.unencrypted_pin_number){
				$rootScope.player.unencrypted_pin_number = decodePin($rootScope.player.pin_number);
			}
		}

        var playersPage = 0;
        ctrl.players = [];
        ctrl.accountCounts = {
            eligible: 0,
            ineligible: 0
        };

		ctrl.checkins = [];
		ctrl.locations = [];
		ctrl.questions = [];
		/*ctrl.accountCheckins = []; */

		$rootScope.checkedInCount = 0;
		$rootScope.showhide = "hide";
		$rootScope.showoption = "hide";

		ctrl.deletePlayer = deletePlayer;
		ctrl.savePlayer = savePlayer;
		ctrl.editPlayer = editPlayer;

		ctrl.getPlayers = getPlayers;
		ctrl.loadPlayers = loadPlayers;

		ctrl.getPlayerById = getPlayerById;
		ctrl.checkoutPlayer = checkoutPlayer;
		ctrl.getLocations = getLocations;
		// TODO : Remove for now, Request clarification if this is needed
		// ctrl.getQuestions = getQuestions;
		ctrl.resetPin = resetPin;

		ctrl.showCheckinForm = showCheckinForm;
		ctrl.checkinPlayer = checkinPlayer;
		ctrl.voidCheckin = voidCheckin;
		ctrl.showReset = showReset;
		ctrl.resetDevice = resetDevice;

        function loadCurrentUser() {
			if($rootScope.globals.currentUser) {
				$rootScope.showhide = "show";

			} else {
				$rootScope.showhide = "hide";
			}
		}

		function decodePin(encryptedPin) {
			return Base64.decode(encryptedPin);
		}
		function voidCheckin(id) {
			LaredoService.VoidCheckin(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
						getCheckins();
						getPlayers();
					}
				});
		}

		function checkinPlayer() {
			LaredoService.CheckinAccount($scope.checkinRecord)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success('Player was checked in successfully', true);
						getCheckins();
						getPlayers();
						$scope.checkinRecord = {"endpoint_type": "admin"};
					}
					$('#checkInModal').modal('hide');
				});
		}

		function showCheckinForm(id) {
			$scope.checkinRecord.account_id = id;
			ctrl.getLocations();
			$('#checkInModal').modal();
		}

		$('#checkInModal').on('hidden.bs.modal', function () {
			$scope.checkinRecord = {"endpoint_type": "admin", "pin_number":"", "location_id":-1};
			$scope.checkinForm.$setPristine();
		});

		function showReset(id) {
			$scope.resetRecord = {};
			$scope.resetRecord.reset_id = id;
			$('#resetModal').modal();
		}

		$('#resetModal').on('hidden.bs.modal', function () {
			$scope.resetRecord = {};
			$scope.resetForm.$setPristine();
		});

		function resetDevice (id) {
			LaredoService.SaveAccount({id: id, device_id:''}).then(function(response) {
				if(response.status == "200") {
					FlashService.Success(response.message, true);
					getPlayers();
				}
			});
		}

		function resetPin() {
			LaredoService.ResetPin($scope.resetRecord)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
					}
					$scope.resetRecord = {};
					$('#resetModal').modal('hide');
				});
		}


		function getCheckins() {
			var locationId = (permissions.hasPermission('is_admin') ? '' : $rootScope.globals.currentLocation);
			LaredoService.GetAccountsCheckedIn( locationId )
				.then(function(accounts) {
					ctrl.checkins = accounts;
					$rootScope.checkedInCount=accounts.length;
				});
		}

		/*function getAccountCheckins(accountId, rowcount) {
			LaredoService.GetCheckinsForAccount(accountId, rowcount)
				.then(function(records) {
					ctrl.accountCheckins = records;
				});
		}*/

		function checkoutPlayer(id) {
			LaredoService.CheckoutAccount(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success('Player was checked out successfully', true);
                        getCheckins();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error(response.message);
					}
				});
		}

		function savePlayer() {
			LaredoService.SaveAccount($rootScope.player)
				.then(function(response) {
                    $location.path($rootScope.lastPage);
				});
		}

		function editPlayer() {
			LaredoService.EditAccount($rootScope.player, permissions.hasPermission('is_admin'))
				.then(function(response) {
					console.log("Edit Account Response: ", response)
					if(response.status === 200 && !response.data.errorMessage){
						FlashService.Success('Player was updated successfully', true);
						$location.path($rootScope.lastPage);
					}else if (response.status >= 500){
						FlashService.Error('Something went wrong! Please try again later or contact help desk.')
					}
				});
		}


        function deletePlayer(id) {
            LaredoService.DeleteAccount(id)
				.then(function (response) {
					if(response.status == "200") {
						FlashService.Success('Removed player successfully', true);
                        getPlayers();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error(response.message);
					}
				});
        }


        function getPlayers() {
            loadPlayers(true);
            LaredoService.GetAccountCount()
                .then(function(counts) {
                    ctrl.accountCounts = counts;
                });
        }

        function resetPlayers() {
            playersPage = 0;
            ctrl.players = [];
		}

        function loadPlayers(reset) {
			var filter = $scope.query || '';
			if (reset) {
                resetPlayers();
			}

            LaredoService.GetAllAccounts(permissions.hasPermission('is_admin') ? '' : $rootScope.globals.currentLocation, filter, playersPage++)
                .then(function (players) {
                    if (reset) {
						ctrl.players = players;
					}
					else {
						ctrl.players = ctrl.players.concat(players);
					}
                });
		}


		function getPlayerById(id) {
			LaredoService.GetAccountById(id)
				.then(function (player) {
					// player.opt_in = (player.opt_in == 1 ? true : false);
					// player.confirm_age = (player.confirm_age == 1 ? true : false);
					// player.confirm_sep = (player.confirm_sep == 1 ? true : false);
					$rootScope.player = player;
				});
		}


		function getLocations() {
			LaredoService.GetAllLocations(permissions)
				.then(function(locations) {
					ctrl.locations = ($rootScope.globals.locations && $rootScope.globals.locations.length > 0 ? $rootScope.globals.locations : locations);
				});
		}


		// TODO : Remove for now, Request clarification if this is needed
		// function getQuestions() {
        //     LaredoService.GetAllQuestions()
		// 		.then(function(questions) {
		// 			ctrl.questions = questions;
		// 		});
		// }


		loadCurrentUser();
		if($rootScope.globals.currentUser) {
			getCheckins();
			getPlayers();

			var isEnrolling = $.inArray($location.path(), ['/enroll']) != -1;
			var isEditing = ($routeParams.playerId && $routeParams.playerId.length > 0);

			if(isEnrolling || isEditing) {
				// TODO : Remove for now, Request clarification if this is needed
				// getQuestions();
				getLocations();

				if(isEditing) {
					ctrl.getPlayerById($routeParams.playerId);
				} else {
					$rootScope.player.registered_location = $rootScope.globals.currentUser.defaultLocation;
					delete $rootScope[$rootScope.flashName];
				}
			}
		}


		$scope.predicate = 'time_in';
		$scope.reverse = true;
		$scope.order = function(predicate) {
			$scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
			$scope.predicate = predicate;
		};


		$scope.toggleModal = function() {
			if(!$rootScope.player.opt_in == 1)
				$('#myModal').modal();
		};


		$scope.phoneNumberPattern = (function() {
			var regexp = /^[0-9\(\)\-\s]{10,14}$/;
			return {
				test: function(value) {
					return (value.length > 0 && regexp.test(value));
				}
			};
		})();


	}
})();
