(function () {
    'use strict';

	angular.module('app').filter('permissionFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				angular.forEach(input, function(obj) {
					if ( re.test(obj.name) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});
	
    angular.module('app').controller('PermissionController', PermissionController);

    PermissionController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function PermissionController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		
		$scope.permission = {};
		$scope.permissions = [];
		
		ctrl.getPermissions = function() {
			LaredoService.GetPermissions()
				.then(function(permissions) {
					$scope.permissions = permissions;
				});
		};
		
		ctrl.getPermissionById = function (id) {
			LaredoService.GetPermissionById(id)
				.then(function(permission) {
					$scope.permission = permission;
					//console.log(permission);
				});
		};
		
		ctrl.editPermission = function (id) {
			if(id) {
				$scope.permission = ctrl.getPermissionById(id);
			} else {
				$scope.permission = {};
			}
			
			$('#permissionModal').modal();
		};
		
		ctrl.savePermission = function () {
			LaredoService.SavePermission($scope.permission)
			.then(function(response) {
				if(response.status == "200") {
					ctrl.getPermissions();
					$('#permissionModal').modal('hide');
				} else {
					//$scope.dataLoading = false;
				}
			});
		};
		
		ctrl.deletePermission  = function (id) {
           LaredoService.DeletePermission(id)
				.then(function(response) {
					if(response.status == "200") {
                        ctrl.getPermissions();
					} else {
						//$scope.dataLoading = false;
					}
				});
        };
		
		
		if($routeParams.permissionId && $routeParams.permissionId.length > 0) {
			if($routeParams.permissionId > -1)
				ctrl.getPermissionById($routeParams.permissionId);
		} else {
			ctrl.getPermissions();
			
		}
    }
})();