(function () {
    'use strict';
    var DrawingNoLocationSelection = {
        id: null,
        name: 'No Location Selected'
    };

    function getLocationForDrawing(referral) {
        return referral.location_id
            ? {
                id: referral.location_id,
                name: referral.location_name
            }
            : DrawingNoLocationSelection;
    }
    angular.module('app').controller('ReferralCodeEditController', ReferralCodeEditController);

    ReferralCodeEditController.$inject = ['ReferralCodeService', 'LaredoService', 'FlashService', '$scope', '$location', '$routeParams', 'permissions'];
    function ReferralCodeEditController(ReferralCodeService, LaredoService, FlashService, $scope, $location, $routeParams, permissions) {
        var ctrl = this;

        $scope.referral = {
            code: '',
            location_id: undefined,
            location_name: undefined,
            award: undefined,
            expires: undefined,
            marketingType: undefined
        };

        if($routeParams.referralId > 0) {
            ReferralCodeService.get($routeParams.referralId)
                .then(function(referral) {
                    if (referral.expires) {
                        referral.expires = new Date(referral.expires);
                    }
                    $scope.referral = referral;
                    ctrl.locations.selected = getLocationForDrawing(referral);
                });
        }

        ctrl.marketingTypeOptions = ReferralCodeService.marketingTypeOptions;

		ctrl.expiresDatePickerOptions = {
		    minDate: new Date()
		};

		ctrl.openExpiresPicker = function() {
			ctrl.expiresPickerOpen = true;
		};

        ctrl.save = save;

        function save() {
            const referral = $scope.referral;
            const location = ctrl.locations.selected;
            referral.code = referral.code.toUpperCase();

            if(location && location.id > 0){
                referral.location_id = location.id;
            }else{
                referral.location_id = null;
            }

            const prom = referral.id > 0
                ? ReferralCodeService.update(referral.id, referral)
                : ReferralCodeService.create($scope.referral);

			prom.then(function() {
                $location.path('/referrals');
            });
        }

        ctrl.locations = {
            data: [],
            selected: undefined
        };

        (ctrl.resetLocation = function() {
            ctrl.locations.selected = DrawingNoLocationSelection;
        })();

        (ctrl.loadLocations = function() {
            LaredoService.GetAllLocations(permissions)
                .then(function(locations) {
                    ctrl.locations.data = locations;
                });
        })();
    }
})();