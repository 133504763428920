(function () {
    'use strict';

    angular.module('app').controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', 'permissions', '$rootScope'];
    function ResetPasswordController(LaredoService, FlashService, $scope, $location, $routeParams, permissions, $rootScope) {
        var ctrl = this;
		$scope.employee = {};
		$scope.employees = [];
		
		ctrl.saveEmployee = function () {
			LaredoService.ResetPassword($scope.employee)
			.then(function(response) {
				if(response.status == "200") {
                    $location.path('#players');
                    FlashService.Success(response.message, true);
				}
			});
		};

		ctrl.getEmployees = function() {
			LaredoService.GetAllEmployees( (permissions.hasPermission('is_admin') ? '' : ctrl.company_id)  )
				.then(function(employees) {
					$scope.employees = employees;
				});
		};
		
		if($routeParams.employeeId && $routeParams.employeeId.length > 0) {
			$scope.employee.id = $routeParams.employeeId;
			ctrl.getEmployees();
		} else {
			$location.path('#players');
		}
    }
})();