(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuestionController', QuestionController);

    QuestionController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams'];
    function QuestionController(LaredoService, FlashService, $scope, $location, $routeParams) {
        var ctrl = this;

		$scope.question = {};
		$scope.questions = [];
		
		ctrl.getQuestionById = function (id) {
            LaredoService.GetQuestionById(id)
				.then(function(question) {
					$scope.question = question;
				});
		};
		
		ctrl.getQuestions = function () {
            LaredoService.GetAllQuestions()
				.then(function(questions) {
					$scope.questions = questions;
				});
		};
		
		ctrl.deleteQuestion = function (id) {
			LaredoService.DeleteQuestion(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        ctrl.getQuestions();
					} else {
						FlashService.Error(response.message);
					}
				});
		};
		
		ctrl.saveQuestion = function () {
			LaredoService.SaveQuestion($scope.question)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        $location.path('/questions');
					} else {
						FlashService.Error(response.message);
					}
				});
		};
		
		
		if($routeParams.questionId && $routeParams.questionId.length > 0) {
			ctrl.getQuestionById($routeParams.questionId);
		} else {
			ctrl.getQuestions();
		}
		
    }

})();