(function () {
    'use strict';

	angular.module('app').filter('offerFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.title) || re.test(obj.modifyUserName) || re.test(obj.frequency) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	})
	.directive('eventchoice', function () {
		return {
			restrict: 'A',
	        scope: true,
	        link: function(scope, element, attributes) {
	        	
	        }
	    };
	});

    angular.module('app').controller('OfferController', OfferController);

    OfferController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function OfferController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;

		$scope.offer = {accounts:[],type:'other'};
		$scope.closingOffer = {isClosing:1, closed:1};
		$scope.offers = [];
		$scope.types = [
			{'id':'kioskgame','name':'Kiosk Game'},
			{'id':'pickgame','name':'Sports Pick Game'},
			{'id':'earn','name':'Earn and Get'},
			{'id':'progressive', 'name':'Progressive'},
			{'id':'hotseat', 'name':'Hot Seat'},
			{'id':'other','name':'Other'}
		];
		$scope.pickTypes = ['Football','Baseball','Basketball','Soccer','Hockey'];
		$scope.eligibility = ['all','invite'];
		$scope.arrays = {
			"choices": [],
			"choices2": []
		}

		$scope.addChoice = function(ary, field) {
			$scope.arrays[ary].push($('#'+field).val());
			$scope.offer[ary] = $scope.arrays[ary].join(',');
			$('#'+field).val('');
		};

		$scope.removeChoice = function(ary, value) {
			var idx = $scope.arrays[ary].indexOf(value);
			$scope.arrays[ary].splice(idx, 1);
			$scope.offer[ary] = $scope.arrays[ary].join(',');
		};

		$scope.startDateOptions = {
		    minDate: new Date()
		};

		$scope.endDateOptions = {
		    minDate: $scope.offer.end_date
		};

		$scope.redeemDateOptions = {
		    minDate: $scope.offer.end_date
		};

		//$scope.timepickerOptions = {minute-step:15};

		$scope.$watch("seatRunDate", function() {
            
        });

		$scope.openDate = function(popupId) {
			$scope[popupId].opened = true;
		};

		$scope.startDate = { opened: false };
		$scope.endDate = { opened: false };
		$scope.redeemBy = { opened: false };
		$scope.seatRunDate = { 
			opened: false,
			timepickerOptions: {
				minuteStep:15,
				readonlyInput:true
			}
		};

		$scope.resetPickOptions = function () {
			$scope.offer.end_date = '';
			$scope.offer.redeem_by = '';
			$scope.offer.choices = '';
			$scope.offer.choices2 = '';
			$scope.offer.pick_type = -1;
		};


		/*  INVITE ACCOUNT FUNCTIONALITY  */
		$scope.accounts = [];
		$scope.searchForm = {interval:7};
		$scope.searchAccounts = function (){
			LaredoService.SearchAccounts($scope.searchForm)
				.then(function(accounts) {
					$scope.accounts = accounts;
				});
		};
		
		ctrl.getOffers = function() {
			LaredoService.GetOffers()
				.then(function(offers) {
					$scope.offers = (offers.success == false ? [] : offers);
				});
		};
		
		ctrl.getOfferById = function (id) {
			LaredoService.GetOfferById(id)
				.then(function(offer) {
					offer.start_date = new Date(offer.start_date);
					offer.end_date = new Date(offer.end_date);
					offer.redeem_by = new Date(offer.redeem_by);
					offer.seatRunDate = new Date(offer.seatRunDate);

					$scope.offer = offer;
					$scope.arrays.choices = offer.choices ? offer.choices.split(",") : [];
					$scope.arrays.choices2 = offer.choices2 ? offer.choices2.split(",") : [];
					$scope.accounts = offer.accounts;

					//console.log(offer);
				});
		};
		
		ctrl.saveOffer = function () {
			$scope.offer.modifyUser = $rootScope.globals.currentUser.id;
			$scope.offer.accounts = $scope.accounts;
			if(!$scope.offer.id) {
				$scope.offer.createUser = $scope.offer.modifyUser;
			}

			$scope.offer.start_date.setUTCHours(12,0,0,0);
			$scope.offer.end_date.setUTCHours(12,0,0,0);
			$scope.offer.redeem_by.setUTCHours(12,0,0,0);

			LaredoService.SaveOffer($scope.offer)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
						$location.path('/offers');
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error(response.message);
					}
				});
		};
		
		ctrl.deleteOffer = function (id) {
           LaredoService.DeleteOffer(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        ctrl.getOffers();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
					}
				});
        };

        ctrl.closeOffer = function() {
        	LaredoService.SaveOffer($scope.closingOffer)
        		.then(function(response) {
        			ctrl.getOffers();
        			$('#closeOfferModal').modal('hide');
        		});
        };

		ctrl.showForm = function (id, choices, choices2, label1, label2) {
			$scope.closingOffer.id = id;
			$scope.tieBreakerLabel1 = label1;
			$scope.tieBreakerLabel2 = label2;
			$scope.choices = choices ? choices.split(",") : [];
			$scope.choices2 = choices2 ? choices2.split(",") : [];
			$('#closeOfferModal').modal();
		};
		
		$('#closeOfferModal').on('hidden.bs.modal', function () {
			$scope.closeOffer = {};
			$scope.choices = [];
			$scope.choices2 = [];
			$scope.closeOfferForm.$setPristine();
		});
		
		if($routeParams.offerId && $routeParams.offerId.length > 0) {
			if($routeParams.offerId > -1)
				ctrl.getOfferById($routeParams.offerId);
			else
				$scope.offer = {accounts:[],type:'other'};
		} else {
			ctrl.getOffers();
		}
		
    }
})();