/*   NOTIFICATION CONTROLLER  */
(function () {
    'use strict';

    angular.module('app').controller('NotificationController', NotificationController);

    NotificationController.$inject = ['AppEnums', 'AppConstants', 'LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function NotificationController(AppEnums, AppConstants, LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;

        // enum
        ctrl.AdHocMessageType = AppEnums.AdHocMessageType;
		ctrl.charLimit = {
		    Push: 100,
            Email: 2000
        };

		ctrl.criteriaOptions = AppConstants.GroupMessageCriteria

        $scope.notifications = [];

		ctrl.calcRemainingChars = function() {
		    var limit = $scope.notification.type == AppEnums.AdHocMessageType.Email
                ? ctrl.charLimit.Email
                : ctrl.charLimit.Push;
		    return limit - ($scope.notification.message && $scope.notification.message.length || 0);
        };


		$scope.openDate = function(popupId) {
			$scope[popupId].opened = true;
		};

		$scope.sendDate = { 
			opened: false,
			timepickerOptions: {
				minuteStep:15,
				readonlyInput:true
			}
		};
		
		ctrl.getNotifications = function() {
			LaredoService.GetNotifications().then(function(records) {
				$scope.notifications = records;
			});
		};

		var DefaultNotif = {
            type: AppEnums.AdHocMessageType.Push
        };

		ctrl.setDefaultMessage = function() {
            $scope.notification = Object.assign({}, DefaultNotif);
        };

		ctrl.resetForTypeChange = function() {
		    $scope.notification = Object.assign({}, DefaultNotif, {type: $scope.notification.type});
			$scope.notifForm.$setPristine();
            $scope.notifForm.$setUntouched();
        };

		$scope.editNotification = function (id) {
			if(id) {
				ctrl.getNotificationById(id);
			} else {
                ctrl.setDefaultMessage();
			}

			$('#notifModal').modal();
		};

		$scope.deleteNotification  = function (id) {
           LaredoService.DeleteNotification(id).then(function(response) {
				FlashService.Success(response.message, true);
                ctrl.getNotifications();
			});
        };

        ctrl.getNotificationById = function (id) {
        	LaredoService.GetNotificationById(id).then(function(notification) {
				$scope.notification = notification;
				$scope.notification.send_date = new Date($scope.notification.send_date);
			});
        };

		$scope.saveNotification = function () {
			if($scope.notification.criteria < 3){
				$scope.notification.criteria_value = 0
			}
			LaredoService.SaveNotification($scope.notification).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
					ctrl.getNotifications();
					$('#notifModal').modal('hide');
				}
			});
		};

        ctrl.setDefaultMessage();
		ctrl.getNotifications();
		
    }
})();