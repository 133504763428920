(function () {
    'use strict';

    angular
        .module('app')
        .factory('RcrHttpService', RcrHttpService);

    RcrHttpService.$inject = ['$http', '$rootScope', 'FlashService'];
    function RcrHttpService($http, $rootScope, FlashService) {
        var baseAPIUrl = $rootScope.baseAPIUrl;

        return {
            get: get,
            post: post,
            put: put,
            'delete': del
        };

        function generateURIQuery(queryObj) {
            var objKeys = queryObj && Object.keys(queryObj) || null;

            if (!objKeys) {
                return '';
            }

            return '?' + objKeys
                .map(function(key) {
                        var value = queryObj[key],
                            flatValue = value && typeof value === 'object' ? JSON.stringify(value) : value;
                    return encodeURIComponent(key) + '=' + encodeURIComponent(flatValue);
                })
                .join('&');
        }

        function isUnauthorizedError(error) {
            return error.status == "401" || error.status == "403"
        }

        function handleError(response) {
            //console.log(response);
            var errorMessage = "An unknown error occurred.  We were unable to process your request.";
            if(isUnauthorizedError(response)) {
                errorMessage = "You are not authorized to perform that action.";
            } else if(response.data && response.data.message) {
                errorMessage = response.data.message;
            }
            FlashService.Error(errorMessage);

            // TODO if it's not an error we know how to handle we should continue the error so the caller's catch can handle it
            // instead of checking for an error in the then as below in the checkAuthHandleError
            return { success: false, message: errorMessage, statusCode: response.status };
        }

        function handleResponse(request) {
            return request
                .then(function(result) {
                    return result.data;
                })
                .catch(handleError);
        }

        function get(url, queryObj) {
            return handleResponse($http.get(baseAPIUrl + '/' + url + generateURIQuery(queryObj)));
        }
        
        function post(url, data) {
            return handleResponse($http.post(baseAPIUrl + '/' + url, data));
        }

        function put(url, dataUpdate) {
            return handleResponse($http.put(baseAPIUrl + '/' + url, dataUpdate));
        }

        function del() {
            return handleResponse($http.delete(baseAPIUrl + '/' + url));
        }
    }

})();