(function () {
    'use strict';
	
	angular.module('app').filter('empFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.first_name + ' ' + obj.last_name) || re.test(obj.username) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});

    angular.module('app').controller('EmployeeController', EmployeeController);

    EmployeeController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', 'permissions', '$rootScope'];
    function EmployeeController(LaredoService, FlashService, $scope, $location, $routeParams, permissions, $rootScope) {
        var ctrl = this;
        ctrl.company_id = $rootScope.globals.currentUser.companyId;
		
		$scope.employee = {employee_locations:[], password:'', company_id:ctrl.company_id };
		$scope.employees = [];
		$scope.locations = [];
		$scope.companies = [];
		$scope.roles = [];
		//$scope.loading = (ctrl.dataLoading || true ? "show" : "hide");
		
		$scope.toggleSelection = function (locId) {
			var idx = $scope.employee.employee_locations.indexOf(locId);

			if (idx > -1) {
				$scope.employee.employee_locations.splice(idx, 1);
			} else {
				$scope.employee.employee_locations.push(locId);
			}
		};
		
		ctrl.getEmployees = function() {
			var compId = permissions.hasPermission('is_admin') ? '' : ctrl.company_id;
			var roleId = $rootScope.globals.currentUser.roleId;
			LaredoService.GetAllEmployees( compId, roleId )
				.then(function(employees) {
					$scope.employees = employees;
				});
		};
		
		ctrl.getEmployeeById = function (id) {
			LaredoService.GetEmployeeById(id)
				.then(function(employee) {
					employee.super_user = (employee.super_user == 1 ? true : false);
					$scope.employee = employee;
					//console.log(employee);
				});
		};
		
		ctrl.saveEmployee = function () {
			console.log("saveEmployee", $scope.employee)
			LaredoService.SaveEmployee($scope.employee)
			.then(function(response) {
				if(response.status == "200") {
					FlashService.Success(response.message, true);
                    $location.path('/employees');
				} else if (response.status == "401") {
					FlashService.Error("You do not have permission to perform that action");
				} else {
					FlashService.Error(response.message);
				}
			});
		};
		
		ctrl.deleteEmployee = function (id) {
           LaredoService.DeleteEmployee(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        ctrl.getEmployees();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
					}
				});
        };
		
		ctrl.getAllLocations = function () {
            LaredoService.GetAllLocations(permissions)
				.then(function(locations) {
					$scope.locations = locations;
				});
		};

		ctrl.getAllCompanies = function () {
            LaredoService.GetAllCompanies()
				.then(function(companies) {
					$scope.companies = companies;
				});
		};

		ctrl.getSubRoles = function () {
			LaredoService.GetSubRoles($rootScope.globals.currentUser.roleId)
				.then(function(roles) {
					$scope.roles = roles;
				});
		};
		
		if($routeParams.employeeId && $routeParams.employeeId.length > 0) {
			if($routeParams.employeeId > -1)
				ctrl.getEmployeeById($routeParams.employeeId);
			ctrl.getAllLocations();
			ctrl.getAllCompanies();
			ctrl.getSubRoles();
		} else {
			ctrl.getEmployees();
		}
		
    }
})();
