(function () {
    'use strict';

	angular.module('app').filter('prizeTypeFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.title) || re.test(obj.modifyUserName) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});
	
    angular.module('app').controller('PrizeTypeController', PrizeTypeController);

    PrizeTypeController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function PrizeTypeController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		
		$scope.prizeType = {};
		$scope.companies = [];
		ctrl.dataLoading = true;
		
		ctrl.getPrizeTypes = function() {
			LaredoService.GetPrizeTypes()
				.then(function(prizeTypes) {
					$scope.prizeTypes = prizeTypes;
					ctrl.dataLoading = false;
				});
		};
		
		ctrl.getPrizeTypeById = function (id) {
			LaredoService.GetPrizeTypeById(id)
				.then(function(prizeType) {
					$scope.prizeType = prizeType;
					//console.log(prizeType);
				});
		};
		
		ctrl.editPrizeType = function (id) {
			if(id) {
				$scope.prizeType = ctrl.getPrizeTypeById(id);
			} else {
				$scope.prizeType = {};
			}
			
			$('#prizeTypeModal').modal();
		};
		
		ctrl.savePrizeType = function () {
			$scope.prizeType.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.SavePrizeType($scope.prizeType)
			.then(function(response) {
				if(response.status == "200") {
					FlashService.Success(response.message, true);
					ctrl.getPrizeTypes();
					$('#prizeTypeModal').modal('hide');
				} else if (response.status == "401") {
					FlashService.Error("You do not have permission to perform that action");
				} else {
					FlashService.Error(response.message);
					ctrl.dataLoading = false;
				}
			});
		};
		
		ctrl.deletePrizeType  = function (id) {
           LaredoService.DeletePrizeType(id)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
                        ctrl.getPrizeTypes();
					} else if (response.status == "401") {
						FlashService.Error("You do not have permission to perform that action");
					} else {
						FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
						ctrl.dataLoading = false;
					}
				});
        };

        ctrl.getAllCompanies = function () {
            LaredoService.GetAllCompanies()
				.then(function(companies) {
					$scope.companies = companies;
				});
		};
		
		
		if($routeParams.prizeTypeId && $routeParams.prizeTypeId.length > 0) {
			if($routeParams.prizeTypeId > -1)
				ctrl.getPrizeTypeById($routeParams.prizeTypeId);
			ctrl.getAllCompanies();
		} else {
			ctrl.getPrizeTypes();
			
		}
		
    }
})();