(function () {
    'strict';

    angular
        .module('app')
        .factory('ReferralCodeService', ReferralCodeService);

    ReferralCodeService.$inject = ['AppEnums', 'AppEnumDescriptions', 'AppEnumOptions', 'RcrHttpService'];
    function ReferralCodeService(AppEnums, AppEnumDescriptions, AppEnumOptions, RcrHttpService) {

        var marketingTypes = AppEnums.MarketingType;
        var marketingTypesDescription = AppEnumDescriptions.MarketingType;

        return {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            getMarketingType: getMarketingType,
            marketingTypes: marketingTypesDescription,
            marketingTypeOptions: AppEnumOptions.MarketingType
                .filter(function(option) { return option.type != marketingTypes.Unspecified && option.type != marketingTypes.Referrer; }) // remove unspecified
        };

        function getAll(filter, page) {
            console.log("Filter" , filter);
            if (filter && page){
                return RcrHttpService.get('referral', {filter: filter, page: page});
            }
            else if (page){
                return RcrHttpService.get('referral', {page: page});
            }
            else{
                return RcrHttpService.get('referral', {filter: filter});
            }
            
        }

        function get(id) {
            return RcrHttpService.get('referral/' + id);
        }

        function create(referralCode) {
            return RcrHttpService.post('referral', referralCode);
        }

        function update(id, referralCode) {

            /**
             * Rapid fix for manipulating javascript object since we are joining multiple tables and then updating it back to database
             * we need to make sure that the data to be sent corresponds to the table column names.
             * @type {{expires: *, award: *, code, marketingTypes: (*|any[]|{}), referrer_id, created: *, id, location_id: (Document.form.location_id|Document.checkinForm.location_id|number|*)}}
             */
            const toUpdateReferral = {
                id: referralCode.id,
                award: referralCode.award,
                code: referralCode.code,
                created: referralCode.created,
                expires: referralCode.expires,
                location_id: referralCode.location_id,
                marketingTypes: referralCode.marketingTypes,
                referrer_id: referralCode.referrer_id
            }

            return RcrHttpService.put('referral/' + id, toUpdateReferral);
        }

        function getMarketingType(type) {
            var desc = marketingTypesDescription[type];
            return desc || 'Unknown';
        }
    }
})();