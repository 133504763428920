(function () {
    'use strict';

	angular.module('app').filter('appConfigFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.code) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});
	
    angular.module('app').controller('AppConfigController', AppConfigController);

    AppConfigController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope', 'textAngularManager'];
    function AppConfigController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope, textAngularManager) {
        var ctrl = this;
		
		$scope.appConfig = {};
		$scope.appConfigs = [];
		$scope.stripHtmlTags = false;

		
		ctrl.getAppConfigs = function() {
			LaredoService.GetAppConfigs()
				.then(function(configs) {
					$scope.appConfigs = configs;
				});
		};
		
		ctrl.getAppConfigById = function (id) {
			LaredoService.GetAppConfigById(id)
				.then(function(config) {
					$scope.appConfig = config;
					//console.log(config);
				});
		};
		
		ctrl.editAppConfig = function (id) {
			if(id) {
				$scope.appConfig = ctrl.getAppConfigById(id);
			} else {
				$scope.appConfig = {};
			}
			
			$('#appConfigModal').modal();
		};
		
		ctrl.saveAppConfig = function () {
			LaredoService.SaveAppConfig($scope.appConfig).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
					ctrl.getAppConfigs();
					$('#appConfigModal').modal('hide');
				} else {
					FlashService.Error(response.message);
				}
			});
		};
		
		ctrl.deleteAppConfig = function (id) {
           	LaredoService.DeleteAppConfig(id).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
                    ctrl.getAppConfigs();
				} else {
					FlashService.Error( (response.message ? response.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
					ctrl.dataLoading = false;
				}
			});
        };
		
		ctrl.getAppConfigs();
    }
})();