﻿(function () {
    'use strict';

    angular
        .module('app')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$location', 'AuthenticationService', 'FlashService', '$rootScope', 'Idle', 'permissions','$scope'];
    function LoginController($location, AuthenticationService, FlashService, $rootScope, Idle, permissions,$scope) {
        var ctrl = this;

        ctrl.login = login;

        (function initController() {
            // reset login status
            AuthenticationService.ClearCredentials();
			Idle.unwatch();
        })();

        function login() {
            AuthenticationService.Login(ctrl.username, ctrl.password, function (response) {
				//console.log(response);
                if (response.status === 200) {
                    AuthenticationService.SetCredentials(ctrl.username, response.data);
                    $rootScope.showhide = "show";
                    if(permissions.hasPermission("view_accounts"))
                        $location.path('/players');
                    else if(permissions.hasPermission("view_employees"))
                        $location.path('/employees');
                    else if(permissions.hasPermission("view_diagnostics"))
                        $location.path('/diagnostics');
                } else {
                    FlashService.Error( (response.data.message ? response.data.message : "An unexpected error occurred.  Please contact the Laredo help desk for assistance." ) );
                }
            });
        };
    }

})();
