/*  DRAWING CONTROLLER  */
(function () {
    'use strict';

    var DrawingNoLocationSelection = {
        id: null,
        name: 'All locations'
    };

    function getLocationForDrawing(drawing) {
        return drawing.location_id
            ? {
                id: drawing.location_id,
                name: drawing.locationName
            }
            : DrawingNoLocationSelection;
    }

    angular.module('app').filter('drawingWinnerListFilter', function() {
       
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				// Using the angular.forEach method, go through the array of data and perform the operation of figuring out if the language is statically or dynamically typed.
				angular.forEach(input, function(obj) {
					if ( re.test(obj.title || re.test(obj.potentialWinner) )) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}
			return out;
		}
	});

    angular.module('app').controller('DrawingController', DrawingController);
    DrawingController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function DrawingController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
		$scope.maxPageSize = 5;
        $scope.currentPage = 0;
        $scope.drawings = [];
        $scope.gap = 5;


        $scope.numPages = function () {
            return 20;
          };

          $scope.range = function(size, start, end) {
            var ret = [];
            console.log(size, start, end);
        
            if (size < end) {
              end = size;
              start = size - $scope.gap;
            }
            for (var i = start; i < end; i++) {
              ret.push(i);
            }
            console.log(ret);
            return ret;
          };
        

        $scope.prevPage = function() {
            console.log("QUERY" , $scope.filter);
            if ($scope.currentPage > 0) {
              $scope.currentPage--;
              ctrl.getDrawings();
            }
          };
        
          $scope.nextPage = function() {
            if ($scope.currentPage < $scope.drawings.length - 1) {
              $scope.currentPage++;
              ctrl.getDrawings();
            }
          };
        
          $scope.setPage = function() {
              console.log("PAGE" , $scope.currentPage, this.n);
            $scope.currentPage = this.n;
            ctrl.getDrawings();
          };


          $scope.getDrawings = function() {
			LaredoService.GetDrawings($scope.query || '', $scope.currentPage).then(function(records) {
				$scope.drawings = records;
			});
		};

		ctrl.getDrawings = function() {
			LaredoService.GetDrawings($scope.query || '', $scope.currentPage).then(function(records) {
				$scope.drawings = records;
			});
		};
		
		ctrl.getDrawingsCount = function() {
			LaredoService.GetDrawingsCount().then(function(result) {
				$scope.drawingsCount = result.noOfActiveDrawings;
			});
		};

		

		$scope.deleteDrawing = function (id) {
           LaredoService.DeleteDrawing(id).then(function(response) {
				if(!response.statusCode) {
					FlashService.Success(response.message, true);
                    ctrl.getDrawings();
				}
			});
        };



        ctrl.getDrawingsCount();
        ctrl.getDrawings();



    }



    angular.module('app').controller('DrawingViewController', DrawingViewController);
    DrawingViewController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function DrawingViewController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;
        $scope.drawing = {};
        $scope.drawingDate = {};
        $scope.multiplier = {};
        $scope.winners = [];

        ctrl.locations = {selected: DrawingNoLocationSelection};

		LaredoService.GetDrawingDetails($routeParams.drawingId).then(function(response) {
			$scope.drawing = response;
			ctrl.locations.selected = getLocationForDrawing(response);
		});

		$scope.GetDrawingWinners = function (dateId) {
            console.log("Drawing: " + $routeParams.drawingId + "   Date: " + dateId);
			LaredoService.GetDrawingWinners($routeParams.drawingId, dateId).then(function(response) {
                console.log(response);
				$scope.winners = response;
				$('#winnersModal').modal('show');
			});
		};

        
		$scope.PrepareRerunConfirmModal = function(dateId) { 
            $scope.dateId = dateId;
            $scope.drawingId = $routeParams.drawingId;
            $('#rerunConfirmModal').modal('show');
        }


        $scope.PerformRerun = function(drawingId, dateId){
            LaredoService.PerformRerun(drawingId , dateId).then( (response) => {
                console.log("Response: ", response);       
                if(response.status == 200) {
					FlashService.Success(response.data.message, true);
				}
                $('#rerunConfirmModal').modal('hide');
            })
        }


    }

    angular.module('app').controller('DrawingViewReportController', DrawingViewReportController);
    DrawingViewReportController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope', 'permissions'];
    function DrawingViewReportController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope, permissions) {
        var ctrl = this;
        $scope.drawingWinnerlist = []

        ctrl.locations = {selected: DrawingNoLocationSelection};

		LaredoService.GetDrawingDetails($routeParams.drawingId).then(function(response) {
			$scope.drawing = response;
			ctrl.locations.selected = getLocationForDrawing(response);
		});

        LaredoService.GetDrawingWinnerList($routeParams.drawingId , $routeParams.dateId).then( (response) => {
            console.log("Response: ", response);       
            $scope.drawingWinnerlist = response.data;
            ctrl.count = $scope.drawingWinnerlist.length;
        });



        ctrl.getDrawingWinnerList = function(){
            console.log("getDrawingWinnerList");
            const drawingId = $routeParams.drawingId;
            const dateId = $routeParams.dateId;
            LaredoService.GetDrawingWinnerList(drawingId, dateId).then(function(result){
                console.log("Control Winnerlist Response: ", result );
                $scope.drawingWinnerListCount = result.data.length;
            });
        };
		

    }


    angular.module('app').controller('DrawingEditController', DrawingEditController);
    DrawingEditController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope', 'Upload', 'permissions'];
    function DrawingEditController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope, Upload, permissions) {
        var ctrl = this;

        $scope.file;
        $scope.drawing = {};
        $scope.drawingDate = {};
        $scope.multiplier = {};
        $scope.isFinalSet = false;


        var d = new Date();
        d.setMinutes(0);

        $scope.datepickerOptions = {
		    minDate: new Date()
		};

		$scope.startDate = { 
            opened: false,
            timepickerOptions: {
                minuteStep:15,
                readonlyInput:true
            }
        };
		$scope.endDate = { 
            opened: false,
            timepickerOptions: {
                minuteStep:15,
                readonlyInput:true
            } 
        };

        $scope.runDateOptions = {
            minDate: d
        };

		$scope.runDate = { 
			opened: false,
			timepickerOptions: {
				minuteStep:15,
				readonlyInput:true
			},
            buttonBar: {
                show: true,
                now: {
                    show: false,
                    text: 'Now',
                    cls: 'btn-sm btn-default'
                },
                today: {
                    show: true,
                    text: 'Today',
                    cls: 'btn-sm btn-default'
                },
                clear: {
                    show: true,
                    text: 'Clear',
                    cls: 'btn-sm btn-default'
                },
                date: {
                    show: true,
                    text: 'Date',
                    cls: 'btn-sm btn-default'
                },
                time: {
                    show: true,
                    text: 'Time',
                    cls: 'btn-sm btn-default'
                },
                close: {
                    show: true,
                    text: 'Close',
                    cls: 'btn-sm btn-default'
                }
            }
		};

        $scope.multiplierStartDate = { 
            opened: false,
            datepickerOptions: {
                minDate: new Date(),
                maxDate: new Date()
            },
            timepickerOptions: {
                minuteStep:15,
                readonlyInput:true
            }
        };
        $scope.multiplierEndDate = { 
            opened: false,
            datepickerOptions: {
                minDate: new Date(),
                maxDate: new Date()
            },
            timepickerOptions: {
                minuteStep:15,
                readonlyInput:true
            } 
        };
		

		$scope.openDate = function(popupId) {
			$scope[popupId].opened = true;
		};

        $scope.SaveDrawing = function (picFile) {
            $scope.file = picFile;
            $scope.drawing.location_id = ctrl.locations.selected.id;

            LaredoService.ValidateDrawing($scope.drawing).then(function (resp) {
                if(resp.isValid) {
                    if(picFile) 
                        $scope.UploadImageAndSave();
                    else
                        LaredoService.SaveDrawing($scope.drawing).then($scope.DrawingSaveResponse);
                } else if(!resp.isValid) {
                    FlashService.Error(resp.message, false);
                }
            });
        };

        $scope.DrawingSaveResponse = function (response) {
            if(!response.statusCode) {
                if(!$scope.drawing.id)
                    $scope.drawing.id = response.drawingId;
                FlashService.Success(response.message, true);
                $location.path('/drawings');
            }
        };

        $scope.UploadImageAndSave = function() {
            console.log("Upload with region ", process.env.REGION)
            if (!process.env.REGION){
                AWS.config.region = 'us-east-2'
            }else{
                AWS.config.region = process.env.REGION;
            }
            
            // TODO HUGE MISTAKE HERE, must be taken out
            var s3 = new AWS.S3({
                accessKeyId: process.env.accessKeyId,
                secretAccessKey: process.env.secretAccessKey
            });

            //var params = { Key: $scope.file.name, ContentType: $scope.file.type, Body: $scope.file, ServerSideEncryption: 'AES256' };
            var params = {
                Bucket : "psgapp-rewards-artwork",
                Key : $scope.file.name,
                ContentType: $scope.file.type,
                Body : $scope.file
            };

            // policy:  arn:aws:s3:::rcrewards

            s3.putObject(params, function(err, data) {
                if(err) {
                    // There Was An Error With Your S3 Config
                    console.log("Error : " , err, data)
                    alert(err.message);
                    return false;
                } else {
                    // Success!
                    alert('Upload Done');
                    $scope.drawing.grand_prize_url = `https://${params.Bucket}.s3.amazonaws.com/${$scope.file.name}`;
                    LaredoService.SaveDrawing($scope.drawing).then($scope.DrawingSaveResponse);
                }
            })
                .on('httpUploadProgress',function(progress) {
                    // Log Progress Information
                    console.log(Math.round(progress.loaded / progress.total * 100) + '% done');
                });

        };

        $scope.GetDrawingDetails = function (id) {
        	LaredoService.GetDrawingDetails(id).then(function(response) {
				$scope.drawing = response;

				// TODO note, that with the version of ui-select (0.19.8), opening doesn't scroll to initially selected item set (https://github.com/angular-ui/ui-select/issues/976)
                ctrl.locations.selected = getLocationForDrawing(response);

				$scope.drawing.start_date = new Date($scope.drawing.start_date);
				$scope.drawing.end_date = new Date($scope.drawing.end_date);
                $scope.runDateOptions.minDate = new Date($scope.drawing.start_date);
                $scope.runDateOptions.initDate = new Date($scope.drawing.start_date);

                // be careful setting initDate without copying the date as the same reference is used
                // $scope.multiplierStartDate.datepickerOptions.initDate = new Date($scope.drawing.start_date);
                $scope.multiplierStartDate.datepickerOptions.minDate = Date.now() < $scope.drawing.start_date.getTime()
                    ? new Date($scope.drawing.start_date)
                    : new Date();
                $scope.multiplierStartDate.timepickerOptions.min = $scope.multiplierStartDate.datepickerOptions.minDate;
                $scope.multiplierStartDate.datepickerOptions.maxDate = $scope.drawing.end_date;
                $scope.multiplierStartDate.timepickerOptions.max = $scope.drawing.end_date;

                // $scope.multiplierEndDate.datepickerOptions.initDate = new Date($scope.drawing.start_date);
                $scope.multiplierEndDate.datepickerOptions.minDate = $scope.multiplierStartDate.datepickerOptions.minDate;
                $scope.multiplierEndDate.timepickerOptions.min = $scope.multiplierEndDate.datepickerOptions.minDate;
                $scope.multiplierEndDate.datepickerOptions.maxDate = $scope.drawing.end_date;
                $scope.multiplierEndDate.timepickerOptions.max = $scope.drawing.end_date;

                /*  CHECK FOR FINAL DRAWING  */
                var today = new Date();
                console.log(today);
                for(var x=0; x < response.dates.length; x++) {
    
                    response.dates[x].editable = new Date(response.dates[x].run_date) > today;
                    console.log("Date: "+ response.dates[x].run_date + "  Editable: " + (new Date(response.dates[x].run_date) > today));

                    if(response.dates[x].type == 'final') {
                        $scope.isFinalSet = true;
                        $scope.runDateOptions.maxDate = new Date(response.dates[x].run_date);
                        $scope.runDate.timepickerOptions.max = new Date(response.dates[x].run_date);
                        //console.log($scope.runDateOptions);
                        //console.log($scope.runDate);
                    }
                }

                console.log(response.dates);
			});
        };
		
		/*******************************
        ******    DRAWING DATE    ******
        *******************************/
        $scope.OpenDrawingDateForm = function (obj) {
        	if(obj) {
        		obj.run_date = new Date(obj.run_date);
        		$scope.drawingDate = obj;
        	}
        		
        	$('#dateModal').modal('show');
        };

        $scope.SaveDrawingDate = function () {
            var min = $scope.drawingDate.run_date.getMinutes();
            if(min != 0 && min != 15 && min!=30 && min!=45) {
                alert('The run date for a drawing must be in a 15 minute increment');
                return;
            }

            delete $scope.drawingDate.editable;
        	$scope.drawingDate.drawing_id = $scope.drawing.id;
        	LaredoService.SaveDrawingDate($scope.drawingDate).then(function(response) {
        		if(!response.statusCode) {
        			FlashService.Success(response.message, true);
        			$('#dateModal').modal('hide');
        			LaredoService.GetDrawingDates($routeParams.drawingId).then(function(response2) {
        				if(!response2.statusCode)
        					$scope.drawing.dates = response2;
        			});
        		}
        	});
        };

        $scope.DeleteDrawingDate = function (id) {
        	LaredoService.DeleteDrawingDate($scope.drawing.id, id).then(function(response) {
        		if(!response.statusCode) {
        			FlashService.Success(response.message, true);
        			LaredoService.GetDrawingDates($routeParams.drawingId).then(function(response2) {
        				if(!response2.statusCode)
        					$scope.drawing.dates = response2;
        			});
        		}
        	});
        };

        
        /*******************************
        ******     MULTIPLIERS    ******
        *******************************/
        $scope.OpenMultiplierForm = function (obj) {
        	if(obj) {
        		obj.start_date = new Date(obj.start_date);
        		obj.end_date = new Date(obj.end_date);
        		$scope.multiplier = obj;
        	}

        	$('#multiplierModal').modal('show');
        };

        $scope.SaveMultiplier = function () {
        	$scope.multiplier.drawing_id = $scope.drawing.id;
        	LaredoService.SaveMultiplier($scope.multiplier).then(function (response) {
        		if(!response.statusCode) {
        			FlashService.Success(response.message, true);
        			$('#multiplierModal').modal('hide');
        			LaredoService.GetMultipliers($routeParams.drawingId).then(function(response2) {
        				console.log(response2);
        				if(!response2.statusCode)
        					$scope.drawing.multipliers = response2;
        			});
        		}
        	});
        };

        $scope.DeleteMultiplier = function (id) {
        	LaredoService.DeleteMultiplier(id).then(function (response) {
        		if(!response.statusCode) {
        			FlashService.Success(response.message, true);
        			LaredoService.GetMultipliers($routeParams.drawingId).then(function(response2) {
        				if(!response2.statusCode)
        					$scope.drawing.multipliers = response2;
        			});
        		}
        	});
        };


        if($routeParams.drawingId.length > 0 && $routeParams.drawingId != -1) {
        	$scope.GetDrawingDetails($routeParams.drawingId);
        }


        /**************************************************
         * Location
         **************************************************/

        ctrl.locations = {
            data: [],
            selected: undefined
        };

        (ctrl.resetLocation = function() {
            ctrl.locations.selected = DrawingNoLocationSelection;
        })();

        (ctrl.loadLocations = function() {
            LaredoService.GetAllLocations(permissions)
                .then(function(locations) {
                    ctrl.locations.data = locations;
                });
        })();

    }
})();
