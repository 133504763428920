(function () {
    'use strict';
	
	angular.module('app').filter('companyFilter', function() {
		return function(input, query) {
			var out = [];
			
			if(query && query.length > 0) {
				var re = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
				angular.forEach(input, function(obj) {
					if ( re.test(obj.name) ) {
						out.push(obj)
					}
				});
			} else {
				out = input;
			}

			return out;
		}
	});

    angular.module('app').controller('CompanyController', CompanyController);

    CompanyController.$inject = ['LaredoService', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope'];
    function CompanyController(LaredoService, FlashService, $scope, $location, $routeParams, $rootScope) {
        var ctrl = this;

		$scope.companies = [];
		$scope.company = {};
		
		ctrl.getCompanyById = function (id) {
			LaredoService.GetCompanyById(id)
				.then(function(company) {
					$scope.company = company;
				});
		};
		
		ctrl.saveCompany = function () {
			$scope.company.modifyUser = $rootScope.globals.currentUser.id;
			LaredoService.SaveCompany($scope.company)
				.then(function(response) {
					if(response.status == "200") {
	                    $location.path('/companies');
					} 
				});
		};
		
		ctrl.deleteCompany = function (id) {
        	LaredoService.DeleteCompany(id)
				.then(function(response) {
					if(response.status == "200") {
	                    ctrl.getAllCompanies();
					} 
				});
        };

		ctrl.getAllCompanies = function () {
            LaredoService.GetAllCompanies()
				.then(function(companies) {
					$scope.companies = companies;
				});
		};
		
		if($routeParams.companyId && $routeParams.companyId.length > 0 && $routeParams.companyId > -1) {
			ctrl.getCompanyById($routeParams.companyId);
		} 
		ctrl.getAllCompanies();
    }
})();