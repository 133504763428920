﻿(function () {
    'use strict';

    angular
        .module('app')
        .factory('LaredoService', LaredoService);

    LaredoService.$inject = ['$http', '$rootScope', 'FlashService'];
    function LaredoService($http, $rootScope, FlashService) {
        var service = {};
		var baseAPIUrl = $rootScope.baseAPIUrl;

		/* employee functions */
        service.GetAllEmployees = GetAllEmployees;
        service.GetEmployeeById = GetEmployeeById;
        service.GetEmployeeByUsername = GetEmployeeByUsername;
        service.SaveEmployee = SaveEmployee;
        service.DeleteEmployee = DeleteEmployee;
        service.ResetPassword = ResetPassword;
		service.GetAccountRepByLocId = GetAccountRepByLocId;
		service.GetAllAccountRep = GetAllAccountRep;

		/* account functions */
        service.GetAllAccounts = GetAllAccounts;
        service.GetAccountCount = GetAccountCount;
		service.GetAccountById = GetAccountById;
        service.SaveAccount = SaveAccount;
		service.EditAccount = EditAccount;

		service.DeleteAccount = DeleteAccount;
		service.CheckoutAccount = CheckoutAccount;
		service.CheckinAccount = CheckinAccount;
		service.VoidCheckin = VoidCheckin;
		service.ResetPin = ResetPin;
		service.GetAccountCheckins = GetAccountCheckins;
		service.GetAccountCheckinsDetail = GetAccountCheckinsDetail;
		service.GetAccountsCheckedIn = GetAccountsCheckedIn;
		service.GetCheckinEntries = GetCheckinEntries;
		service.GetRewardsForAccount = GetRewardsForAccount;
		service.GetOffersPromosForAccount = GetOffersPromosForAccount;
		service.SearchAccounts = SearchAccounts;

		/*  location functions */
		service.GetAllLocations = GetAllLocations;
		service.GetLocationById = GetLocationById;
		service.SaveLocation = SaveLocation;
		service.DeleteLocation = DeleteLocation;
		service.RecoverLocation = RecoverLocation;
		service.GetLocationBeacons = GetLocationBeacons;
		service.AddLocationBeacon = AddLocationBeacon;

		/*  question functions  */
		service.GetAllQuestions = GetAllQuestions;
		service.GetQuestionById = GetQuestionById;
		service.SaveQuestion = SaveQuestion;
		service.DeleteQuestion = DeleteQuestion;

		/*  prize type functions  */
		service.GetPrizeTypes = GetPrizeTypes;
		service.GetPrizeTypeById = GetPrizeTypeById;
		service.SavePrizeType = SavePrizeType;
		service.DeletePrizeType = DeletePrizeType;

		/*  prize functions  */
		service.GetPrizes = GetPrizes;
		service.GetPrizeById = GetPrizeById;
		service.SavePrize = SavePrize;
		service.DeletePrize = DeletePrize;

		/*  offer functions  */
		service.GetOffers = GetOffers;
		service.GetOfferById = GetOfferById;
		service.SaveOffer = SaveOffer;
		service.DeleteOffer = DeleteOffer;
		// service.GetAccountOffers = GetAccountOffers;
		service.GetWinners = GetWinners;

		/*  offer redemption functions  */
		service.GetRedemptions = GetRedemptions;
		service.UpdateRedemption = UpdateRedemption;

		/*  company functions  */
		service.GetAllCompanies = GetAllCompanies;
		service.GetCompanyById = GetCompanyById;
		service.SaveCompany = SaveCompany;
		service.DeleteCompany = DeleteCompany;

		/*  route functions*/
		service.GetRouteById = GetRouteById;
		service.GetAllRoutes = GetAllRoutes;

		/*  permission functions  */
		service.GetPermissions = GetPermissions;
		service.GetPermissionsByRole = GetPermissionsByRole;
		service.GetPermissionById = GetPermissionById;
		service.SavePermission = SavePermission;
		service.DeletePermission = DeletePermission;

		/*  role functions  */
		service.GetRoles = GetRoles;
		service.GetRoleById = GetRoleById;
		service.SaveRole = SaveRole;
		service.DeleteRole = DeleteRole;
		service.GetSubRoles = GetSubRoles;

		/*  notification functions  */
		service.GetNotifications = GetNotifications;
		service.GetNotificationById = GetNotificationById;
		service.SaveNotification = SaveNotification;
		service.DeleteNotification = DeleteNotification;

		/*  drawing functions  */
		service.GetDrawings = GetDrawings;
		service.GetDrawingsCount = GetDrawingsCount;
		service.GetDrawingById = GetDrawingById;
		service.GetDrawingDetails = GetDrawingDetails;
		service.GenerateDrawingImgUploadPolicy = GenerateDrawingImgUploadPolicy;
		service.UploadDrawingImg = UploadDrawingImg;
		service.SaveDrawing = SaveDrawing;
		service.DeleteDrawing = DeleteDrawing;

		/*  drawing functions  */
		service.GetDrawingDates = GetDrawingDates;
		service.GetDrawingDateById = GetDrawingDateById;
		service.SaveDrawingDate = SaveDrawingDate;
		service.ValidateDrawing = ValidateDrawing;
		service.DeleteDrawingDate = DeleteDrawingDate;
		service.GetDrawingWinners = GetDrawingWinners;
		service.GetDrawingWinnerList = GetDrawingWinnerList;
		service.PerformRerun = PerformRerun;

		/*  drawing winner emails functions  */
		service.GetDrawingWinnersEmail = GetDrawingWinnersEmail;
		service.SaveDrawingWinnersEmail = SaveDrawingWinnersEmail;
		service.DeleteDrawingWinnersEmail = DeleteDrawingWinnersEmail;

		/*  multiplier functions  */
		service.GetMultipliers = GetMultipliers;
		service.SaveMultiplier = SaveMultiplier;
		service.DeleteMultiplier = DeleteMultiplier;

		/*  earning functions  */
		// service.GetEntriesForAccount = GetEntriesForAccount;
		service.GetTotalEntriesForCurrentDrawing = GetTotalEntriesForCurrentDrawing;
		// service.GetTimeRemainder = GetTimeRemainder;
		service.UpdateEntryStatus = UpdateEntryStatus;
		// service.AddEntry = AddEntry;
		// service.DeleteEntry = DeleteEntry;

		/*  app config  */
		service.GetAppConfigs = GetAppConfigs;
		service.GetAppConfigById = GetAppConfigById;
		service.SaveAppConfig = SaveAppConfig;
		service.DeleteAppConfig = DeleteAppConfig;

		/*  app versions  */
		service.GetAppVersions = GetAppVersions;
		service.GetAppVersionById = GetAppVersionById;
		service.SaveAppVersion = SaveAppVersion;
		service.DeleteAppVersion = DeleteAppVersion;

		/*  diagnostic functions  */
		service.GetAllDiagnostic = GetAllDiagnostic;
		service.GetDiagnosticById = GetDiagnosticById;

        return service;


		/*** ROUTE FUNCTIONS ***/

		function GetRouteById(id) {
			return $http.get(baseAPIUrl + '/route/' + id).then(handleSuccess, handleError);
		}

		function GetAllRoutes() {
			return $http.get(baseAPIUrl + '/route').then(handleSuccess, handleError);
		}

        /*** COMPANY FUNCTIONS  ***/
        function GetAllCompanies() {
            return $http.get(baseAPIUrl + '/company').then(handleSuccess, handleError);
        }



        function GetCompanyById(id) {
            return $http.get(baseAPIUrl + '/company/' + id).then(handleSuccess, handleError);
        }

        function SaveCompany(company) {
			if(company.id) {
				return $http.put(baseAPIUrl + '/company', company).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/company', company).then(handleSuccess, handleError);
			}
        }z

        function DeleteCompany(id) {
            return $http.put(baseAPIUrl + '/company/' + id, {}).then(handleSuccess, handleError);
        }


		/*** EMPLOYEE FUNCTIONS  ***/
        function GetAllEmployees(companyId, roleId) {
        	return $http.get(baseAPIUrl + '/employee?companyId=' + companyId + '&roleId=' + roleId  ).then(handleSuccess, handleError);
        }

        function GetEmployeeById(id) {
            return $http.get(baseAPIUrl + '/employee/' + id).then(handleSuccess, handleError);
        }

        function GetEmployeeByUsername(username) {
            return $http.get(baseAPIUrl + '/employee?q=' + username).then(handleSuccess, handleError);
        }

		function GetAccountRepByLocId(locationId) {
			return $http.get(baseAPIUrl + '/employee/rep/location/' + locationId).then(handleSuccess, handleError);
		}

		function GetAllAccountRep() {
			return $http.get(baseAPIUrl + '/employee/rep').then(handleSuccess, handleError);
		}


        function SaveEmployee(employee) {
			if(employee.id) {
				return $http.put(baseAPIUrl + '/employee', employee).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/employee', employee).then(handleSuccess, handleError);
			}
        }

        function DeleteEmployee(id) {
            return $http.put(baseAPIUrl + '/employee/' + id, {}).then(handleSuccess, handleError);
        }

        function ResetPassword(employee) {
        	return $http.post(baseAPIUrl + '/employee/reset', employee).then(handleSuccess, handleError);
        }


		/***  ACCOUNT CHECKINS  ***/
		function GetAccountsCheckedIn(locationId) {
			return $http.get(baseAPIUrl + '/checkins' + (locationId ? '?locationId=' + locationId : "")).then(handleSuccess, handleError);
		}

		function GetCheckinEntries(checkInId) {
            return $http.get(baseAPIUrl + '/checkins/' + checkInId + '/entries').then(handleSuccess, handleError);
		}


		/*** ACCOUNT FUNCTIONS  ***/
		function GetAllAccounts(locationId, filter, page) {
            return $http.get(baseAPIUrl + '/account?locationId=' + locationId + '&filter=' + filter + '&page=' + page).then(handleSuccess, handleError);
        }

        function GetAccountCount() {
			return $http.get(baseAPIUrl + '/account/count').then(handleSuccess, handleError);
		}

        function GetAccountById(id) {
            return $http.get(baseAPIUrl + '/account/' + id).then(handleSuccess, handleError);
        }

		function CheckoutAccount(id) {
			return $http.post(baseAPIUrl + '/accountChk/checkout', {"account_id":id}).then(handleSuccess, handleError);
		}

		function CheckinAccount(rec) {
			return $http.post(baseAPIUrl + '/accountChk/checkin', rec).then(handleSuccess, handleError);
		}

		function VoidCheckin(id) {
			return $http.put(baseAPIUrl + '/accountChk/checkin/' + id).then(handleSuccess, handleError);
		}

		function ResetPin(account) {
			return $http.post(baseAPIUrl + '/account/adminreset', angular.toJson(account)).then(handleSuccess, handleError);
		}

        function SaveAccount(account) {
			if(account.id) {
				return $http.put(baseAPIUrl + '/account', angular.toJson(account)).then(handleSuccess, handleError);
			} else {
				account.override = 1;
				return $http.post(baseAPIUrl + '/account', angular.toJson(account)).then(handleSuccess, handleError);
			}
        }


		function EditAccount(account, isAdmin) {
			if(account.id) {
				let editAccount = {};
				if (isAdmin){
					editAccount = {
						first_name: account.first_name,
						flags: account.flags,
						id: account.id,
						last_name: account.last_name,
						settings: {emailPromos: account.settings.emailPromos, smsReminders: account.settings.smsReminders},
						verified: Number(account.verified),
						eligibility_reason: account.eligibility_reason
					}

					if(account.phone_number){
						editAccount.phone_number = account.phone_number;
					}
					return $http.put(baseAPIUrl + '/account/edit', angular.toJson(editAccount)).then(handleElevatedSuccess, handleError);

				}else{
					editAccount = {
						first_name: account.first_name,
						flags: account.flags,
						id: account.id,
						last_name: account.last_name,
						settings: {emailPromos: account.settings.emailPromos, smsReminders: account.settings.smsReminders},
						verified: Number(account.verified)
					}
					return $http.put(baseAPIUrl + '/account', angular.toJson(editAccount)).then(handleElevatedSuccess, handleError);
				}




			}else{
				const response = {
					status:  500
				};
				return handleError(response);
			}
		}

        function DeleteAccount(id) {
            return $http.put(baseAPIUrl + '/account/' + id, {}).then(handleSuccess, handleError);
        }

		function GetRewardsForAccount(cardId) {
			return []; //$http.get('/api/accounts/rewards/' + cardId).then(handleSuccess, handleError('Error retrieving accounts that are checked in'));
		}

		function GetOffersPromosForAccount(cardId) {
			return []; //$http.get('/api/accounts/offerspromos/' + cardId).then(handleSuccess, handleError('Error retrieving accounts that are checked in'));
		}

		function GetAccountCheckins(id, limit, ascending) {
			return $http.get(baseAPIUrl + '/accountChk/' + id + '/checkins?rowcount=' + limit + (ascending ? '&ascending=1' : '')).then(handleSuccess, handleError);
		}

        function GetAccountCheckinsDetail(id, limit) {
            return $http.get(baseAPIUrl + '/accountChk/' + id + '/checkins/detail?rowcount=' + limit).then(handleSuccess, handleError);
        }

		function SearchAccounts(searchForm) {
			return $http.post(baseAPIUrl + '/account/search', searchForm).then(handleSuccess, handleError);
		}


		/*****************************/
		/***  LOCATION FUNCTIONS   ***/
		/*****************************/
		function GetAllLocations(permissions, employeeId) {
			if (employeeId){
				return $http.get(baseAPIUrl + '/location?employeeId=' + employeeId  ).then(handleSuccess, handleError);

			}else if(permissions.hasPermission('is_admin') ){
				return $http.get(baseAPIUrl + '/location?deleted=true'   ).then(handleSuccess, handleError);
			}else{
				return $http.get(baseAPIUrl + '/location'   ).then(handleSuccess, handleError);

			}
		}



		function GetLocationById(id) {
            return $http.get(baseAPIUrl + '/location/' + id).then(handleSuccess, handleError);
		}

		function SaveLocation(location) {
			if(location.id) {
				return $http.put(baseAPIUrl + '/location', angular.toJson(location)).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/location', angular.toJson(location)).then(handleSuccess, handleError);
			}
		}

		function DeleteLocation(id) {
			return $http.put(baseAPIUrl + '/location/' + id, {}).then(handleElevatedSuccess, handleError);
		}

		function RecoverLocation(id) {
			return $http.put(baseAPIUrl + '/location/recover', {locationId: id}).then(handleElevatedSuccess, handleError);

		}

		function GetLocationBeacons(id) {
            return $http.get(baseAPIUrl + '/location/' + id + '/beacons').then(handleSuccess, handleError);
		}

		function AddLocationBeacon(id, newBeacon) {
            return $http.post(baseAPIUrl + '/location/' + id + '/beacons', newBeacon).then(handleSuccess, handleError);
		}


		/*****************************/
		/***   QUESTION FUNCTIONS  ***/
		/*****************************/
		function GetAllQuestions(companyId) {
            return $http.get(baseAPIUrl + '/question').then(handleSuccess, handleError);
		}

		function GetQuestionById(id) {
            return $http.get(baseAPIUrl + '/question/' + id).then(handleSuccess, handleError);
		}

		function SaveQuestion(question) {
			if(question.id) {
				return $http.put(baseAPIUrl + '/question', question).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/question', question).then(handleSuccess, handleError);
			}
		}

		function DeleteQuestion(id) {
			return $http.put(baseAPIUrl + '/question/' + id, {}).then(handleSuccess, handleError);
		}



		/*****************************/
		/**  PRIZE TYPE FUNCTIONS  ***/
		/*****************************/
		function GetPrizeTypes(companyId) {
            return $http.get(baseAPIUrl + '/prizetype' + (companyId ? '?companyId=' + companyId : '')  ).then(handleSuccess, handleError);
		}

		function GetPrizeTypeById(id) {
            return $http.get(baseAPIUrl + '/prizetype/' + id).then(handleSuccess, handleError);
		}

		function SavePrizeType(prizetype) {
			if(prizetype.id) {
				return $http.put(baseAPIUrl + '/prizetype', prizetype).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/prizetype', prizetype).then(handleSuccess, handleError);
			}
		}

		function DeletePrizeType(id) {
			//return $http['delete'](baseAPIUrl + '/prizetype/' + id).then(handleSuccess, handleError);
			return $http.put(baseAPIUrl + '/prizetype/' + id, {}).then(handleSuccess, handleError);
		}



		/*****************************/
		/*****  PRIZE FUNCTIONS  *****/
		/*****************************/
		function GetPrizes(companyId, offerId) {
			var qString = (companyId ? '?companyId=' + companyId : '');
			var querySep = (companyId ? "&" : "?");
			qString += (offerId ? querySep + "offerId=" + offerId : "");

            return $http.get(baseAPIUrl + '/prize' + qString).then(handleSuccess, handleError);
		}

		function GetPrizeById(id) {
            return $http.get(baseAPIUrl + '/prize/' + id).then(handleSuccess, handleError);
		}

		function SavePrize(prize) {
			if(prize.id) {
				return $http.put(baseAPIUrl + '/prize', prize).then(handleSuccess, handleError);
			} else {
				prize.createUser = prize.modifyUser;
				return $http.post(baseAPIUrl + '/prize', prize).then(handleSuccess, handleError);
			}
		}

		function DeletePrize(id) {
			return $http.put(baseAPIUrl + '/prize/' + id, {}).then(handleSuccess, handleError);
		}


		/*****************************/
		/*****  OFFER FUNCTIONS  *****/
		/*****************************/
		function GetOffers(companyId) {
            return $http.get(baseAPIUrl + '/offer' + (companyId ? '?companyId=' + companyId : '') ).then(handleSuccess, handleError);
		}

		function GetOfferById(id) {
            return $http.get(baseAPIUrl + '/offer/' + id).then(handleSuccess, handleError);
		}

		function SaveOffer(offer) {
			if(offer.id) {
				return $http.put(baseAPIUrl + '/offer', offer).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/offer', offer).then(handleSuccess, handleError);
			}
		}

		function DeleteOffer(id) {
			return $http.put(baseAPIUrl + '/offer/' + id, {}).then(handleSuccess, handleError);
		}

		// function GetAccountOffers(accountId) {
		// 	return $http.get(baseAPIUrl + '/accountOffer/' + accountId + '/offers?needAll=1&includeGame=1').then(handleSuccess, handleError);
		// }


		function GetWinners (offerId) {
			return $http.get(baseAPIUrl + '/offerWin/' + offerId + '/winners').then(handleSuccess, handleError);
		}



		/**********************************/
		/**  OFFER REDEMPTION FUNCTIONS  **/
		/**********************************/
		function GetRedemptions (accountId, includePending) {
			return $http.get(baseAPIUrl + '/redemption?accountId=' + accountId + (includePending ? '&includePending=' + includePending : '')).then(handleSuccess, handleError);
		}

		function UpdateRedemption (offerRedemption) {
			return $http.put(baseAPIUrl + '/redemption', offerRedemption).then(handleSuccess, handleError);
		}



        /**********************************/
		/*****  PERMISSION FUNCTIONS  *****/
		/**********************************/
		function GetPermissions() {
            return $http.get(baseAPIUrl + '/permission').then(handleSuccess, handleError);
		}

		function GetPermissionsByRole(roleId) {
            return $http.get(baseAPIUrl + '/permission/' + roleId).then(handleSuccess, handleError);
		}

		function GetPermissionById(id) {
            return $http.get(baseAPIUrl + '/permission/' + id).then(handleSuccess, handleError);
		}

		function SavePermission(permission) {
			if(permission.id) {
				return $http.put(baseAPIUrl + '/permission', permission).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/permission', permission).then(handleSuccess, handleError);
			}
		}

		function DeletePermission(id) {
			return $http.put(baseAPIUrl + '/permission/' + id, {}).then(handleSuccess, handleError);
		}



		/**********************************/
		/*****     ROLE FUNCTIONS     *****/
		/**********************************/
		function GetRoles() {
            return $http.get(baseAPIUrl + '/role').then(handleSuccess, handleError);
		}

		function GetRoleById(id) {
            return $http.get(baseAPIUrl + '/role/' + id).then(handleSuccess, handleError);
		}

		function SaveRole(role) {
			if(role.id) {
				return $http.put(baseAPIUrl + '/role', role).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/role', role).then(handleSuccess, handleError);
			}
		}

		function DeleteRole(id) {
			return $http.put(baseAPIUrl + '/role/' + id, {}).then(handleSuccess, handleError);
		}

		function GetSubRoles(id) {
			return $http.get(baseAPIUrl + '/role/' + id + '/subroles', {}).then(handleSuccess, handleError);
		}


		/**********************************/
		/****  NOTIFICATION FUNCTIONS  ****/
		/**********************************/
		function GetNotifications() {
			return $http.get(baseAPIUrl + '/notifications').then(handleSuccess, handleError);
		}

		function GetNotificationById(id) {
			return $http.get(baseAPIUrl + '/notifications/' + id).then(handleSuccess, handleError);
		}

		function SaveNotification(notification) {
			if(notification.id) {
				return $http.put(baseAPIUrl + '/notifications', notification).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/notifications', notification).then(handleSuccess, handleError);
			}
		}

		function DeleteNotification(id) {
			return $http.delete(baseAPIUrl + '/notifications/' + id).then(handleSuccess, handleError);
		}


		/**********************************/
		/****    DRAWING FUNCTIONS    *****/
		/**********************************/
		function GetDrawings(filter, page) {
			return $http.get(baseAPIUrl + '/drawing?filter='+ filter +'&page=' + page).then(handleSuccess, handleError);
		}

		function GetDrawingsCount() {
			return $http.get(baseAPIUrl + '/drawing/count').then(handleSuccess, handleError);
		}


		function GetDrawingById(id) {
			return $http.get(baseAPIUrl + '/drawing/'+ id).then(handleSuccess, handleError);
		}

		function GetDrawingDetails(id) {
			return $http.get(baseAPIUrl + '/drawing/'+ id + '/details').then(handleSuccess, handleError);
		}

		function GenerateDrawingImgUploadPolicy() {
            return $http.post(baseAPIUrl + '/drawing/uploadpolicy').then(handleSuccess, handleError);
		}

		function UploadDrawingImg(awsHost, formData) {
            return $http.post(awsHost, formData).then(handleSuccess, handleError);
		}

		function SaveDrawing(drawing) {
			if(drawing.id) {
				return $http.put(baseAPIUrl + '/drawing', drawing).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/drawing', drawing).then(handleSuccess, handleError);
			}
		}

		function ValidateDrawing(drawing) {
			return $http.post(baseAPIUrl + '/drawing/valid', drawing).then(handleSuccess, handleError);
		}

		function DeleteDrawing(id) {
			return $http.delete(baseAPIUrl + '/drawing/'+ id).then(handleSuccess, handleError);
		}

		function GetDrawingDates(drawingId) {
			return $http.get(baseAPIUrl + '/drawing/'+ drawingId +'/date').then(handleSuccess, handleError);
		}

		function GetDrawingDateById(drawingId, drawingDateId) {
			return $http.get(baseAPIUrl + '/drawing/'+ drawingId +'/date/'+ drawingDateId).then(handleSuccess, handleError);
		}

		function SaveDrawingDate(drawingDate) {
			if(drawingDate.id) {
				return $http.put(baseAPIUrl + '/drawing/'+ drawingDate.drawing_id + '/date', drawingDate).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/drawing/'+ drawingDate.drawing_id + '/date', drawingDate).then(handleSuccess, handleError);
			}
		}

		function DeleteDrawingDate(drawingId, dateId) {
			return $http.delete(baseAPIUrl + '/drawing/'+ drawingId +'/date/'+ dateId).then(handleSuccess, handleError);
		}


		function GetMultipliers(drawingId) {
			return $http.get(baseAPIUrl + '/drawing/'+ drawingId +'/multiplier').then(handleSuccess, handleError);
		}

		function SaveMultiplier(multiplier) {
			if(multiplier.id) {
				return $http.put(baseAPIUrl + '/drawing/'+ multiplier.drawing_id + '/multiplier', multiplier).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/drawing/'+ multiplier.drawing_id + '/multiplier', multiplier).then(handleSuccess, handleError);
			}
		}

		function DeleteMultiplier(dateId) {
			return $http.delete(baseAPIUrl + '/drawing/date/'+ dateId).then(handleSuccess, handleError);
		}

		function GetDrawingWinners(drawingID, dateID) {
			return $http.get(baseAPIUrl + '/drawing/'+ drawingID +'/date/'+ dateID +'/winners').then(handleSuccess, handleError);
		}

		function GetDrawingWinnerList(drawingID, dateID){
			return $http.get(baseAPIUrl + '/drawing/'+ drawingID +'/date/'+ dateID +'/winnerList').then(handleElevatedSuccess, handleError);
		}

		function PerformRerun(drawingID, dateID){
			return $http.put(baseAPIUrl + '/drawing/'+ drawingID + "/performRerun", {"dateId": dateID} ).then(handleElevatedSuccess, handleError);
		}


		/**********************************/
		/****   DrawingWinnersEmail FUNCTIONS   *****/
		/**********************************/
		function GetDrawingWinnersEmail() {
			return $http.get(baseAPIUrl + '/drawingWinner/email').then(handleSuccess, handleError);
		}

		function SaveDrawingWinnersEmail(config) {
			console.log("SaveDrawingWinnersEmail: ",config);
			if(config.id) {
				return $http.put(baseAPIUrl + '/drawingWinner/email', config).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/drawingWinner/email', config).then(handleSuccess, handleError);
			}
		}

		function DeleteDrawingWinnersEmail(configID) {
			return $http.delete(baseAPIUrl + '/drawingWinner/email/'+ configID).then(handleSuccess, handleError);
		}



		/**********************************/
		/****     ENTRY FUNCTIONS     *****/
		/**********************************/
		// function GetEntriesForAccount(accountId) {
		// 	return $http.get(baseAPIUrl + '/entry/'+ accountId +'/location').then(handleSuccess, handleError);
		// }

        function GetTotalEntriesForCurrentDrawing(accountId, flagged) {
            return $http.get(baseAPIUrl + '/entry/'+ accountId + (flagged ? '?flagged=true' : '')).then(handleSuccess, handleError);
        }

		// TODO these REST endpoints need to be cleaned up, the semantics aren't consistent, but for now we'll go with what's there
		// in this case we can't follow the 'entry/{id}' because it would be inconsistent with its prior use in other methods as an accountId
		function UpdateEntryStatus(entryId, entryUpdate) {
            return $http.put(baseAPIUrl + '/entries/'+ entryId, entryUpdate).then(handleSuccess, checkAuthHandleError);
		}

		// function AddEntry(entry) {
		// 	return $http.post(baseAPIUrl + '/entry', entry).then(handleSuccess, handleError);
		// }
        //
		// function DeleteEntry(accountId, locationId) {
		// 	return $http.delete(baseAPIUrl + '/entry/' + accountId +'/location/'+ locationId).then(handleSuccess, handleError);
		// }

		/**********************************/
		/****   AppConfig FUNCTIONS   *****/
		/**********************************/
		function GetAppConfigs() {
			return $http.get(baseAPIUrl + '/appconfig').then(handleSuccess, handleError);
		}

		function GetAppConfigById(configID) {
			return $http.get(baseAPIUrl + '/appconfig/' + configID).then(handleSuccess, handleError);
		}

		function SaveAppConfig(config) {
			if(config.config_id) {
				return $http.put(baseAPIUrl + '/appconfig', config).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/appconfig', config).then(handleSuccess, handleError);
			}
		}

		function DeleteAppConfig(configID) {
			return $http.delete(baseAPIUrl + '/appconfig/'+ configID).then(handleSuccess, handleError);
		}


		/**********************************/
		/****   AppVersion FUNCTIONS   *****/
		/**********************************/
		function GetAppVersions() {
			return $http.get(baseAPIUrl + '/appversion').then(handleSuccess, handleError);
		}

		function GetAppVersionById(ID) {
			return $http.get(baseAPIUrl + '/appversion/' + ID).then(handleSuccess, handleError);
		}

		function SaveAppVersion(newAppVersion) {
			if(newAppVersion.id) {
				return $http.put(baseAPIUrl + '/appversion', newAppVersion).then(handleSuccess, handleError);
			} else {
				return $http.post(baseAPIUrl + '/appversion', newAppVersion).then(handleSuccess, handleError);
			}
		}

		function DeleteAppVersion(ID) {
			return $http.delete(baseAPIUrl + '/appversion/'+ ID).then(handleSuccess, handleError);
		}

		/**********************************/
		/****   Diagnostic FUNCTIONS   *****/
		/**********************************/
		function GetAllDiagnostic(filter, page, locationId) {
			return $http.get(baseAPIUrl + '/support/diagnostics?filter=' + filter + '&page=' + page + '&locationId=' + locationId).then(handleSuccess, handleError);
		}

		function GetDiagnosticById(id) {
            return $http.get(baseAPIUrl + '/support/diagnostic/' + id).then(handleSuccess, handleError);
		}

        // private functions

		function handleElevatedSuccess(res){
			return res;
		}

        function handleSuccess(res) {
        	//console.log(res);
            return res.data;
        }

        function isUnauthorizedError(error) {
			return error.status == "401" || error.status == "403"
		}

        function handleError(response) {
			console.log("Response : ", response.status, response.data.message);
			var errorMessage = "An unknown error occurred.  We were unable to process your request.";
			if(isUnauthorizedError(response)) {
				errorMessage = "You are not authorized to perform that action.";
			} else if(response.data && response.data.message) {
				errorMessage = response.data.message;
			}
			console.log("Error message : " , errorMessage);
			FlashService.Error(errorMessage);

			// TODO if it's not an error we know how to handle we should continue the error so the caller's catch can handle it
			// instead of checking for an error in the then as below in the checkAuthHandleError
            return { success: false, message: errorMessage, statusCode: response.status };
        }

        function checkAuthHandleError(error) {
            if(isUnauthorizedError(error)) {
                FlashService.Error("You are not authorized to perform that action.");
                return null;
            }
            throw error;
		}


    }

})();
