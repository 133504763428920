(function (string) {
    'use strict';

    angular.module('app').controller('LocationController', LocationController);

    LocationController.$inject = ['LaredoService', 'AppEnumOptions', 'FlashService', '$scope', '$location', '$routeParams', '$rootScope', 'NgMap', 'permissions'];
    function LocationController(LaredoService, AppEnumOptions, FlashService, $scope, $location, $routeParams, $rootScope, NgMap, permissions) {
        var ctrl = this;
        var locationId = $routeParams.locationId;
		// HQ
		$scope.centerPoint =
		{
			lat: 41.7595739,
			lng: -88.3159552

		};
		$scope.accountRep = null;
		$scope.accountReps = []
		$scope.userSearched = false;
		console.log("Center : ", $scope.centerPoint);
		$scope.googleMapsUrl="https://maps.googleapis.com/maps/api/js?key=AIzaSyDu36P-ptyHdtd5FXR6CEm0H05AbrtS98Q&libraries=visualization";
		$scope.googleMapsUrlDrawing="https://maps.googleapis.com/maps/api/js?key=AIzaSyDu36P-ptyHdtd5FXR6CEm0H05AbrtS98Q&libraries=visualization,drawing";

		if(locationId > 0){
			console.log('Loading edit map...');
			NgMap.getMap({id: 'edit_location_map'}).then(function(map) {
				ctrl.map = map
				console.log('Getting Map edit_map: ', ctrl.map);
			});
		}else{
			console.log('Loading add map...');
			NgMap.getMap({id: 'add_location_map'}).then(function(map) {
				ctrl.map = map
				console.log('Getting Map add_map : ', ctrl.map);
			});


		}



		ctrl.NorthSouthOptions = AppEnumOptions.NorthSouthType;

		$scope.location = {
			company_id:$rootScope.globals.currentUser.companyId,
            active: true,
            drawing_enabled: true,
			points: [],
			account_rep_id: 0
		};

		$scope.route = {};

		$scope.locations = [];
		$scope.north_south = []
		$scope.companies = [];
		$scope.routes = [];
		$scope.locationCheckins = [];
		ctrl.point = {
            lat: '',
            lng: ''
        };

		$scope.polygons = [];
		$scope.marker = null;

		ctrl.getAccountRepByLocId = function(id) {
			console.log('retrieving account representative');
			LaredoService.GetAccountRepByLocId(id)
				.then(function(res){
					console.log("GetAccountRepByLocId", res)
					if(res.first_name) {
						$scope.accountRep = res
						if ($scope.location) {
							$scope.location.account_rep_id = res.id
						}
					}
				});
		}

		ctrl.getAllAccountReps = function() {
			console.log('retrieving all account representatives');
			LaredoService.GetAllAccountRep()
				.then(function(res){
					console.log("GetAllAccountRep", res)
					$scope.accountReps = res
				});
		}

		ctrl.onMapOverlayCompleted = function (event) {
			console.log('Map overlay completed: ', event.type);
		};

		ctrl.onPolygonCompleted = function (polygon) {
			console.log('Polygon completed: ', polygon);
			const polygonArray = polygon.getPath().getArray()
			if(polygonArray.length > 0){
				$scope.location.points = [];
				polygon.getPath().getArray().map(coords => {
					$scope.location.points.push(coords.lat() + " " + coords.lng());
				});

			}
			if($scope.polygons.length > 0){
				const polygon = $scope.polygons.pop();
				polygon.setMap(null);

			}
			$scope.polygons.push(polygon);

		};

		$scope.searchCenter = function() {
			$scope.centerPoint.lat = ctrl.point.lat;
			$scope.centerPoint.lng = ctrl.point.lng;
			$scope.userSearched = true;
			console.log('User searched: ', $scope.centerPoint);

			// remove marker if not null
			if($scope.marker != null ){
				$scope.marker.setMap(null);
			}
			$scope.marker = new google.maps.Marker({
				map: ctrl.map ,
				animation: google.maps.Animation.DROP,
				position: new google.maps.LatLng(ctrl.point.lat, ctrl.point.lng),
				title: 'Marker'

			})

			console.log('Marker: ', $scope.marker );
		};

		$scope.clearVertices = function () {
			$scope.location.points = [];
			$scope.polygons.forEach( (x , i) => {
				x.setMap(null);
			});
		};

		$scope.removePoint = function(idx) {
			$scope.location.points.splice(idx,1);
		};



		$scope.onUpdateVertices = function (event) {
			console.log("Event : ", event);
			const shape = document.getElementById('polygon');
			const paths = shape.getAttribute("paths");
			console.log("Paths: ", paths);
			if(paths && !paths.includes("mappedGeoCoordinates")){
				const pathsArray = JSON.parse(paths);
				console.log("PathsArray length: ", pathsArray.length);
				const polygon = new google.maps.Polygon({pathsArray});
				if($scope.polygons.length > 0){
					$scope.polygons.pop();
				}
				$scope.polygons.push(polygon);

			}
		};


		// EDIT POLYGON
		ctrl.loadPolygon = function(){
			// $scope.location.points.forEach((coordinate , i)=>{
			// 	console.log("Test :" , coordinate);
			//
			// });
			const polygon = new google.maps.Polygon({
				editable: true,
				paths: $scope.mappedGeoCoordinates,
				strokeOpacity: 1,
				strokeWeight: 2,
				strokeColor: '#FF0000',
				map: ctrl.map,
			});

			$scope.polygons.push(polygon);
			console.log("Loading polygon..");

			google.maps.event.addListener(polygon.getPath(), 'set_at', ctrl.processVertex);
			google.maps.event.addListener(polygon.getPath(), 'insert_at', ctrl.processVertex);
			google.maps.event.addListener(polygon.getPath(), 'remove_at', ctrl.processVertex);
		}

		ctrl.processVertex = function(e){
			const newCoordinate = this.getAt(e);
			const array = this.getArray();
			$scope.location.points = [];
			console.log("Process Vertex: ", newCoordinate.lat(), newCoordinate.lng() , array.length);
			array.map(coords => {
				$scope.location.points.push(coords.lat() + " " + coords.lng());
				console.log("NewLocation length: ", $scope.location.points.length);
			});


		}



		ctrl.getLocationById = function(id) {
            LaredoService.GetLocationById(id)
				.then(function(location) {
					$scope.location = Object.assign({}, location, {
					    active: !!location.active,
                        drawing_enabled: !!location.drawing_enabled,
                        client_excluded: !!location.client_excluded
                    });
					$scope.mappedGeoCoordinates = [];
					$scope.location.points.forEach((x , i) => {
						const coordinate = x.split(" ");

						$scope.mappedGeoCoordinates.push(new google.maps.LatLng( parseFloat(coordinate[0]) , parseFloat(coordinate[1])));
						console.log("Coordinate / Mapped Coordinates: " , coordinate, $scope.mappedGeoCoordinates);
					});
					if($scope.location.points.length > 0){
						$scope.centerPoint.lat = $scope.mappedGeoCoordinates[0].lat();
						$scope.centerPoint.lng = $scope.mappedGeoCoordinates[0].lng();
					}


					console.log('Mapped Coordinates Stringified : ', JSON.stringify($scope.mappedGeoCoordinates), ctrl.map);
					if(locationId){
						ctrl.loadPolygon();
					}
					return location;
				})
				.then(function(location){
					LaredoService.GetRouteById(location.route_id)
						.then(function(route){
							$scope.route = route;
						});
					if (locationId && locationId > -1) {
						ctrl.getAccountRepByLocId(locationId);
					}
				})
		};

		ctrl.getAllLocations = function () {
			// Account REP = 14
			if($scope.globals.currentUser.roleId === 14){
				LaredoService.GetAllLocations(permissions, $scope.globals.currentUser.id)
					.then(function(locations) {
						$scope.locations = locations;
					});

			}else{
				LaredoService.GetAllLocations(permissions)
					.then(function(locations) {
						$scope.locations = locations;
					});

			}

		};

		ctrl.deleteLocation = function (id) {
			LaredoService.DeleteLocation(id)
            .then(function (response) {
				if(response.status === 200) {
					if(response.message){
						FlashService.Success(response.data.message, true);
					}else{
						FlashService.Success('Successfully deleted a location.', true);
					}
					if($scope.globals.currentLocation){
						ctrl.getAllLocations($scope.globals.currentLocation)
					}else{
						ctrl.getAllLocations();
					}
				} else {
					FlashService.Error(response.data.message);
				}
            });
		};


		ctrl.recoverLocation = function (id) {
			LaredoService.RecoverLocation(id)
				.then(function (response) {
					console.log(response);
					if(response.status === 200) {
						FlashService.Success(response.data.message, true);
						if($scope.globals.currentLocation){
							ctrl.getAllLocations($scope.globals.currentLocation)
						}else{
							ctrl.getAllLocations();
						}
					} else {
						FlashService.Error(response.data.message);
					}
				});
		};



		ctrl.saveLocation = function () {
			console.log("saveLocation", $scope.location)
			LaredoService.SaveLocation($scope.location)
				.then(function(response) {
					if(response.status == "200") {
						FlashService.Success(response.message, true);
						if(!$scope.location.id){
							$location.path('/locations');
						}

					} else {
						FlashService.Error(response.message);
					}
				});
		};

		ctrl.getCheckins = function(locationId) {
			LaredoService.GetAccountsCheckedIn(locationId)
				.then(function(accounts) {
					$scope.locationCheckins = accounts;
				});
		};

		ctrl.checkoutPlayer = function (id) {
			LaredoService.CheckoutAccount(id)
				.then(function(response) {
					ctrl.Checkins();
				});
		};

		ctrl.getAllCompanies = function () {
            LaredoService.GetAllCompanies()
				.then(function(companies) {
					$scope.companies = companies;
				});
		};

		ctrl.getAllRoutes = function () {
			LaredoService.GetAllRoutes()
				.then(function(routes) {
					$scope.routes = routes;
				});
		};

		ctrl.showBeaconAddModal = function() {
			ctrl.beacon = {
				beacon_id: '11111111-1111-1111-1111-111111111111',
				location_id: locationId,
				major: '',
				minor: ''
			};
			$scope.beaconForm.$setPristine();
			$('#beaconAdditionModal').modal();
		};

		ctrl.addBeacon = function() {
			LaredoService.AddLocationBeacon(locationId, ctrl.beacon)
				.then(function(result) {
					getLocationBeacons();
				});
            $('#beaconAdditionModal').modal('hide');
		};

		ctrl.beacons = [];

		function getLocationBeacons() {
			if(locationId && locationId > -1) {
				LaredoService.GetLocationBeacons(locationId)
                .then(function(beacons) {
                    ctrl.beacons = beacons;
                });
			}
		}
		getLocationBeacons();


		if(locationId && locationId.length > 0) {
			if(locationId > -1) {
				$scope.location = ctrl.getLocationById(locationId);
			}
			$scope.locationCheckins = ctrl.getCheckins(locationId);
			ctrl.getAllCompanies();
			ctrl.getAllRoutes();
			ctrl.getAllAccountReps();
		} else {
			$scope.locations = ctrl.getAllLocations();
		}

		$scope.predicate = 'time_in';
		$scope.reverse = true;
		$scope.order = function(predicate) {
			$scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
			$scope.predicate = predicate;
		};

    }

})();
